import authApi from "@api/authApi";
import {
  CompleteDiscordIntegrationAPI,
  CompleteSlackIntegrationAPI,
  GetTeamAPIResponse,
  PaymentPeriod,
  PlanOptionName,
} from "@astronautchat/common-backend";
import {
  CommunityEntity,
  CreateCommunityEntity,
  CustomerEntity,
  CustomerUserEntity,
} from "@astronautchat/schema";
import { communityState } from "@recoil/atoms";
import log from "@services/Log";
import { setRecoil } from "recoil-nexus";

const CustomerService = {
  completeDiscordIntegration: async ({
    code,
    guildId,
    permissions,
    customerId,
    customerUserId,
    communityId,
  }: {
    code: string;
    guildId: string;
    permissions: number;
    customerId: CustomerEntity["customerId"];
    customerUserId: CustomerUserEntity["customerUserId"];
    communityId?: CommunityEntity["communityId"];
  }): Promise<CompleteDiscordIntegrationAPI> => {
    log.info("Sending request to complete the bot Integration", {
      code,
      guildId,
      permissions,
      customerId,
      customerUserId,
      communityId,
    });
    const res = await authApi.post(`/customer/integrate/discord`, {
      code,
      guildId,
      permissions,
      customerId,
      customerUserId,
      communityId,
    });
    return res.data;
  },
  completeSlackIntegration: async ({
    teamId,
    name,
    customerId,
    customerUserId,
    communityId,
  }: {
    teamId: string;
    name: string;
    customerId: CustomerEntity["customerId"];
    customerUserId: CustomerUserEntity["customerUserId"];
    communityId?: CommunityEntity["communityId"];
  }): Promise<CompleteSlackIntegrationAPI> => {
    log.info("Sending request to complete the bot Integration", {
      name,
      teamId,
      customerId,
      customerUserId,
      communityId,
    });
    const res = await authApi.post(`/customer/integrate/slack`, {
      name,
      teamId,
      customerId,
      customerUserId,
      communityId,
    });
    return res.data;
  },
  getTeam: async ({
    customerId,
  }: {
    customerId: CustomerEntity["customerId"];
  }): Promise<GetTeamAPIResponse> => {
    log.info("Getting the Team members", {
      customerId,
    });
    const res = await authApi.get(`/customer/${customerId}/team`);
    return res.data;
  },
  createCommunity: async ({
    customerId,
    name,
    logoUrl,
  }: Pick<CustomerEntity, "customerId"> & CreateCommunityEntity) => {
    log.info("Sending request to create Customer", { customerId, name, logoUrl });
    const res = await authApi.post(`/customer/${customerId}`, { name, logoUrl });
    const data = res.data as CommunityEntity;

    log.info("Setting atom value", { data });

    setRecoil(communityState, {
      communityId: data?.communityId,
      customerId: data?.customerId,
      name: data?.name,
      createdAt: data?.createdAt,
      updatedAt: data?.updatedAt,
      deletedAt: data?.deletedAt,
      logoUrl: data?.logoUrl,
    });

    return res.data;
  },
  getCheckoutPortalUrl: async ({
    customerId,
    newPeriod,
    newPlanName,
  }: {
    customerId: CustomerEntity["customerId"];
    newPeriod: PaymentPeriod;
    newPlanName: PlanOptionName;
  }): Promise<void> => {
    log.info("Requesting the checkout portal URL", {
      customerId,
      newPeriod,
      newPlanName,
    });
    const res = await authApi.post(`/customer/${customerId}/checkout/portal`, {
      newPeriod,
      newPlanName,
    });
    const url = res.data;
    if (url) {
      // @ts-ignore
      window.location.href = url;
    }
  },
  getManagementPortalUrl: async ({
    customerId,
  }: {
    customerId: CustomerEntity["customerId"];
  }): Promise<void> => {
    log.info("Requesting the management portal URL", {
      customerId,
    });
    const res = await authApi.post(`/customer/${customerId}/management/portal`);
    const url = res.data;
    if (url) {
      // @ts-ignore
      window.location.href = url;
    }
  },
};

export default CustomerService;
