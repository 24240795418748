import { Box, Button, Typography } from "@mui/material";
import { UpgradeDialog } from "./UpgradeDialog";
import palette from "@styles/palette";
import { useState } from "react";
import { CustomerEntity } from "@astronautchat/schema";
import { calculateDaysFromNow } from "@utils/calculateDaysFromNow";

export const FreeTrialBlock = ({
  variant,
  hasTrialExpired,
  trialEnd,
}: {
  variant: "white" | "black";
  hasTrialExpired: boolean;
  trialEnd: CustomerEntity["trialEnd"] | undefined;
}) => {
  const [isOpen, setIsOpen] = useState(false);

  const title = hasTrialExpired ? "Your free trial expired" : "You're on a free trial";
  const body = hasTrialExpired
    ? "Your Astronaut free trial has expired. Upgrade now to keep using Astronaut."
    : `Your free trial expires in ${
        trialEnd ? calculateDaysFromNow(new Date(trialEnd * 1000)) : 999
      } days. Upgrade now to keep using Astronaut.`;

  return (
    <Box
      sx={{
        width: "100%",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        gap: 10,
        padding: "20px 10px",
        border: "2px solid",
        borderColor: palette.brand.neutral.darkGray,
        borderRadius: 12,
      }}
    >
      <Typography
        variant="appBodyText"
        color={variant === "black" ? palette.brand.neutral.light : palette.brand.neutral.dark}
        textAlign={"center"}
      >
        {title}
      </Typography>
      <Typography
        variant="appCaption"
        color={variant === "black" ? palette.brand.neutral.gray : palette.brand.neutral.darkGray}
        textAlign={"center"}
      >
        {body}
      </Typography>
      <Button
        variant="primary"
        className="small"
        onClick={() => setIsOpen(true)}
      >
        Upgrade now
      </Button>
      <UpgradeDialog
        planTitle="Starter"
        planName="starter"
        isOpen={isOpen}
        onClose={() => setIsOpen(false)}
      />
    </Box>
  );
};
