import { atom } from "recoil";
import { localStorageEffect } from "./localStorageEffect";

export const collapseNavContainerState = atom({
  key: "collapseNavContainerState",
  default: false as boolean,
});

export const socketState = atom({
  key: "socketState",
  default: false as any,
});

// ID for visitors of a report
export const visitorIdState = atom({
  key: "visitorIdState",
  default: undefined as string | undefined,
  effects: [localStorageEffect("visitorIdState")],
});
