import { demoState, sessState } from "@recoil/atoms";
import { getRecoil, setRecoil } from "recoil-nexus";
import { LogoImage } from "@assets/images/Logo";
import { Box, Button, Typography } from "@mui/material";
import { Row } from "@components/common/Row";
import palette from "@styles/palette";

export const DEMO_CTA_HEIGHT = 56;

export default function DemoCTA() {
  const isDemo = getRecoil(demoState);
  const isLoggedInUser = !!getRecoil(sessState); // valid sessState indicates a user is logged in
  return isDemo ? (
    <Box
      sx={{
        paddingX: 20,
        paddingY: 10,
        backgroundColor: "#DDDDD7",
        display: "flex",
        alignItems: "center",
        height: DEMO_CTA_HEIGHT,
      }}
    >
      <Row
        alignItems={"center"}
        gap={6}
      >
        <LogoImage />
        <Typography variant="appSubheading">Astronaut Demo Account</Typography>
      </Row>
      <a
        href="https://astronaut.chat/use-cases?source=demo-cta"
        style={{
          marginLeft: "auto",
          marginRight: 18,
          textDecoration: "underline",
          textDecorationColor: "#222",
          fontWeight: 600,
          color: "#222",
        }}
      >
        Learn More
      </a>
      <Button
        href="https://astronaut.chat?source=demo-cta"
        style={{
          color: "#F9F9F6",
          fontSize: 18,
          fontWeight: 600,
          lineHeight: "105%",
          padding: 8,
          backgroundColor: "#0C37C7",
          borderRadius: 6,
          cursor: "pointer",
          border: "none",
        }}
      >
        Get Access
      </Button>
      {isLoggedInUser && (
        <Button
          style={{
            position: "absolute",
            bottom: 20,
            right: 20,
            backgroundColor: palette.brand.pink.original,
            color: palette.brand.neutral.lightGray,
            zIndex: 1,
          }}
          onClick={() => {
            setRecoil(demoState, false);
            window.location.reload();
          }}
        >
          Exit Demo
        </Button>
      )}
    </Box>
  ) : null;
}
