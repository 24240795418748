import Image from "next/image";

export const ExpandIcon = ({
  width = 24,
  height = 24,
  color = "gray",
}: {
  width?: number;
  height?: number;
  color: "gray" | "white";
}) => (
  <Image
    alt="Expand icon"
    src={color === "gray" ? "/expand-gray.svg" : "/expand-white.svg"}
    width={width}
    height={height}
    priority
    style={{
      objectFit: "contain",
      width,
      transition: "transform 0.25s linear",
    }}
    sizes={`(max-width: 768px) ${width}px, (max-width: 1200px) ${width}px, ${width}px`}
  />
);
