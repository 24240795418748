import { activePlatformIdState, platformListState } from "@recoil/atoms";
import { useRecoilValue } from "recoil";

export const useActiveSource = () => {
  const platformId = useRecoilValue(activePlatformIdState)!;
  const sources = useRecoilValue(platformListState);
  return {
    platformId,
    platformType: sources?.find((i) => i.platformId === platformId)?.platformType,
    platformName: sources?.find((i) => i.platformId === platformId)?.name,
  };
};
