import { config } from "@utils/config";
import axios from "axios";

/** For endpoints that dont require an AWS Auth token */
const publicApi = axios.create({
  baseURL: config.NEXT_PUBLIC_AUTH_API,
  timeout: 60000,
});

export default publicApi;
