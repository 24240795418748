import Image from "next/image";

export const LineChartIcon = ({ width = 25, height = 25 }: { width?: number; height?: number }) => (
  <Image
    alt="Line chart icon"
    src={"/line-chart.svg"}
    width={width}
    height={height}
    priority
    style={{
      objectFit: "contain",
      width,
    }}
    sizes={`(max-width: 768px) ${width}px, (max-width: 1200px) ${width}px, ${width}px`}
  />
);
