import Image from "next/image";

type ArrowDirection = "left" | "down" | "right" | "up";

export const ArrowWallIcon = ({
  width = 27,
  height = 24,
  direction = "right",
  color = "black",
}: {
  width?: number;
  height?: number;
  direction: ArrowDirection;
  color: "black" | "white";
}) => (
  <Image
    alt="Single arrow pointing at wall icon"
    src={color === "white" ? "/arrow-left-wall-white.svg" : "/arrow-left-wall-black.svg"}
    width={width}
    height={height}
    priority
    style={{
      objectFit: "contain",
      width,
      transform:
        direction === "left"
          ? "rotate(0deg)"
          : direction == "down"
          ? "rotate(90deg)"
          : direction === "right"
          ? "rotate(-180deg)"
          : "rotate(-90deg)",
      transition: "transform 0.25s linear",
    }}
    sizes={`(max-width: 768px) ${width}px, (max-width: 1200px) ${width}px, ${width}px`}
  />
);
