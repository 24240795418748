import { DiscordRoleEntity, PlatformEntity } from "@astronautchat/schema";
import { GetMeAPI } from "@astronautchat/common-backend";

export const rolesResponse = (
  discordPlatformId: PlatformEntity["platformId"],
): GetMeAPI["groups"] => [
    {
      platformId: discordPlatformId,
      discordRoleId: "qlcgqzgv28gfo9dklznim3e5",
      name: "Developer",
    },
    {
      platformId: discordPlatformId,
      discordRoleId: "qlcgqzgv28gfo9dklzncsidm3e5",
      name: "Cosmos AI Support",
    },
    {
      platformId: discordPlatformId,
      discordRoleId: "qlcgqzgv28gfo9dklzncsidm3rseffre5",
      name: "Moderator",
    },
  ];

export const astronautRolesResponse: DiscordRoleEntity[] = [
  {
    discordRoleId: "qlcgqzgv28gfo9dklznim3e5",
    name: "CX Bot",
    platformDiscordId: "qlcgqzgv28gfo9dklznim3e5sadad",
    tagId: "dasda",
    roleId: "dgsfas",
    color: "green",
    position: 0,
    managed: false,
    createdAt: new Date(),
    updatedAt: new Date(),
    deletedAt: null
  },
];
