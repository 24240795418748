import { styled } from "@mui/material";
import Box from "@mui/material/Box";

type Props = {
  color: string;
};

export const Divider = styled(Box)((props: Props) => ({
  width: "100%",
  background: props.color,
  height: 1,
}));
