import palette from "@styles/palette";
import { Box, Typography } from "@mui/material";
import Image from "next/image";
import { Wordmark } from "@assets/images/Wordmark";

type OnboardingProps = {
  children: JSX.Element | JSX.Element[];
  onSkip?: () => void;
  maxBodyWidth?: number | string;
  shouldCenterAlignBody?: boolean;
};

export function Onboarding(props: OnboardingProps) {
  return (
    <Box sx={{ background: palette.brand.neutral.lightGray, height: "100%", width: "100%" }}>
      <Box sx={{ position: "absolute", top: 30, left: 30, zIndex: 100 }}>
        <Wordmark
          width={151}
          color="black"
        />
      </Box>
      {props?.onSkip && (
        <Box sx={{ position: "absolute", top: 30, right: 30, zIndex: 100 }}>
          <Typography
            variant="tertiary"
            color={palette.brand.neutral.darkGray}
            sx={{ textDecoration: "underline", textUnderlineOffset: 3, cursor: "pointer" }}
            onClick={props.onSkip}
          >
            skip
          </Typography>
        </Box>
      )}
      {props.shouldCenterAlignBody ? (
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            width: "100%",
            height: "100%",
          }}
        >
          <Box sx={{ maxWidth: props.maxBodyWidth || 510, width: "100%" }}>{props.children}</Box>
        </Box>
      ) : (
        <Box sx={{ maxWidth: props.maxBodyWidth || 510, width: "100%", height: "100%" }}>
          {props.children}
        </Box>
      )}
    </Box>
  );
}
