import { Box, Button, Typography } from "@mui/material";
import { ChevronIcon } from "@assets/icons/ChevronIcon";
import { useRouter } from "next/router";
import palette from "@styles/palette";
import { Avatar } from "@components/Avatar";
import { LogoutIcon } from "@assets/icons/LogoutIcon";
import { FreeTrialBlock } from "@components/plans/FreeTrialBlock";
import { userState, customerState, customerUserState } from "@recoil/atoms";
import { createAvatarUrl } from "@lib/createAvatarUrl";
import { useRecoilValue } from "recoil";
import log from "@services/Log";
import { checkHasTrialExpired } from "@lib/checkHasTrialExpired";
import Auth from "@services/Auth";

const SectionHeader = ({ title }: { title: string }) => {
  return (
    <Typography
      variant="appCaption"
      color={palette.brand.neutral.darkGray}
      sx={{ marginBottom: 7 }}
    >
      {title.toUpperCase()}
    </Typography>
  );
};

const PageButton = ({
  title,
  pathname,
  onClick,
}: {
  title: string;
  pathname: string;
  onClick: () => void;
}) => {
  return (
    <Button
      variant="typography"
      onClick={onClick}
      sx={{
        marginLeft: 15,
        borderRadius: 6,
        width: "-webkit-fill-available",
        justifyContent: "flex-start",
        textAlign: "left",
        padding: 5,
        background: pathname.includes(title.toLowerCase())
          ? palette.brand.neutral.lightGray
          : "transparent",
      }}
    >
      <Typography
        variant="appBodyText"
        color={palette.brand.neutral.dark}
      >
        {title}
      </Typography>
    </Button>
  );
};

const orgPageTitles = ["General", "Team", "Integrations", "Billing"];

export const NavSettings = () => {
  const user = useRecoilValue(userState);
  const router = useRouter();
  const customer = useRecoilValue(customerState);
  const customerUser = useRecoilValue(customerUserState);
  const isTrialPeriod = customer?.subscriptionStatus === "trialing";
  const hasTrialExpired = checkHasTrialExpired({
    subscriptionStatus: customer?.subscriptionStatus,
    trialEnd: customer?.trialEnd,
  });

  const onSignout = async () => {
    try {
      Auth.signOut();
    } catch (error: any) {
      log.error(error, error?.message);
    }
  };

  const accessOrgPageTitles =
    customerUser?.role !== "admin" && customerUser?.role !== "owner"
      ? orgPageTitles.filter((i) => i !== "Billing" && i !== "Integrations")
      : orgPageTitles;

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        padding: "30px 15px",
        height: "100%",
      }}
    >
      <Button
        variant="typography"
        onClick={() => router.push("/chat")}
        sx={{ marginBottom: 60 }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            gap: 10,
            width: "100%",
          }}
        >
          <ChevronIcon
            width={30}
            height={30}
            direction="left"
            color="black"
          />
          <Typography variant="appSubheading">Settings</Typography>
        </Box>
      </Button>
      <SectionHeader title="Account" />
      {["Profile", "Preferences"].map((i) => (
        <PageButton
          key={i}
          title={i}
          pathname={router.pathname}
          onClick={() => router.push(`/chat/settings/${i.toLowerCase()}`)}
        />
      ))}
      <br />
      <SectionHeader title="Organization" />
      {accessOrgPageTitles.map((i) => (
        <PageButton
          key={i}
          title={i}
          pathname={router.pathname}
          onClick={() => router.push(`/chat/settings/${i.toLowerCase()}`)}
        />
      ))}
      <Box
        sx={{
          height: "100%",
          display: "flex",
          justifyContent: "flex-end",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        {isTrialPeriod && (
          <FreeTrialBlock
            variant="white"
            hasTrialExpired={hasTrialExpired}
            trialEnd={customer?.trialEnd}
          />
        )}
      </Box>
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          justifyContent: "space-between",
          marginTop: 45,
        }}
      >
        <Button
          variant="typography"
          sx={{
            flexDirection: "row",
            alignItems: "center",
            display: "flex",
            gap: 10,
          }}
          onClick={onSignout}
        >
          <LogoutIcon />
          <Typography
            variant="appBodyText"
            color={palette.brand.neutral.darkGray}
          >
            Logout
          </Typography>
        </Button>
        <Avatar
          width={45}
          height={45}
          src={user?.avatarUrl ? createAvatarUrl(user.avatarUrl) : "/default-avatar.png"}
        />
      </Box>
    </Box>
  );
};
