import Image from "next/image";

export const ChatIcon = ({ width = 30, height = 30 }: { width?: number; height?: number }) => (
  <Image
    alt="Chat icon"
    src={"/chat.svg"}
    width={width}
    height={height}
    priority
    style={{
      objectFit: "contain",
      width,
    }}
    sizes={`(max-width: 768px) ${width}px, (max-width: 1200px) ${width}px, ${width}px`}
  />
);
