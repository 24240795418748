import Image from "next/image";

export const SlackIcon = ({
  width = 26,
  height = 26,
  color,
}: {
  width?: number;
  height?: number;
  color: "brand" | "gray" | "dark" | "brand-icon";
}) => (
  <Image
    alt="Slack logo"
    src={
      color === "brand"
        ? "/logo-slack.svg"
        : color === "dark"
        ? "/slack-logo-dark.svg"
        : color === "brand-icon"
        ? "/slack-icon.svg"
        : "/slack-logo-gray.svg"
    }
    width={width}
    height={height}
    priority
    style={{ objectFit: "contain", width }}
    sizes={`(max-width: 768px) ${width}px, (max-width: 1200px) ${width}px, ${width}px`}
  />
);
