import { TypographyOptions } from "@mui/material/styles/createTypography";
import palette from "./palette";

export const k2dFontWeight = {
  normal: 400,
  medium: 500,
  bold: 700,
};

const defaultStyle = {
  fontSize: 14,
  color: "black",
  margin: 0,
  padding: 0,
  fontWeight: 400,
  fontFamily: "var(--font-inter)",
};

const defaultColor = {
  color: palette.brand.neutral.dark,
};

const typography: TypographyOptions = {
  fontFamily: "var(--font-inter)",

  webLargeTitle: {
    fontFamily: "var(--font-k2d)",
    fontSize: `clamp(40px, 5vw, 64px)`,
    fontWeight: 700,
    lineHeight: `110%`,
  },
  webPageTitle: {
    fontFamily: "var(--font-k2d)",
    fontSize: `clamp(30px, 5vw, 48px)`,
    fontWeight: 600,
    lineHeight: `120%`,
  },
  webSubheading: {
    fontFamily: "var(--font-k2d)",
    fontSize: `clamp(26px, 5vw, 32px)`,
    fontWeight: 500,
    lineHeight: `120%`,
  },
  webSubtitle: {
    fontFamily: "var(--font-k2d)",
    fontSize: `clamp(24px, 5vw, 24px)`,
    fontWeight: 500,
    lineHeight: `150%`,
  },
  webButtonText: {
    fontFamily: "var(--font-inter)",
    fontSize: `clamp(20px, 5vw, 20px)`,
    fontWeight: 600,
    lineHeight: `105%`,
  },
  webBodyText: {
    fontFamily: "var(--font-inter)",
    fontSize: `clamp(20px, 5vw, 20px)`,
    fontWeight: 400,
    lineHeight: `160%`,
  },
  webCaptionText: {
    fontFamily: "var(--font-inter)",
    fontSize: `clamp(16px, 5vw, 16px)`,
    fontWeight: 400,
    lineHeight: `150%`,
  },
  appPageTitle: {
    ...defaultColor,
    fontFamily: "var(--font-k2d)",
    fontSize: `clamp(28px, 5vw, 28px)`,
    fontWeight: 600,
    lineHeight: `120%`,
  },
  appPageTitleSecondary: {
    ...defaultColor,
    fontFamily: "var(--font-k2d)",
    fontSize: `clamp(24px, 5vw, 24px)`,
    fontWeight: 500,
    lineHeight: `120%`,
  },
  appSubheading: {
    ...defaultColor,
    fontFamily: "var(--font-k2d)",
    fontSize: `clamp(24px, 5vw, 24px)`,
    fontWeight: 500,
    lineHeight: `120%`,
  },
  appBodyText: {
    ...defaultColor,
    fontFamily: "var(--font-inter)",
    fontSize: `clamp(18px, 5vw, 18px)`,
    fontWeight: 400,
    lineHeight: `150%`,
  },
  appDescription: {
    color: palette.brand.neutral.darkGray,
    fontFamily: "var(--font-inter)",
    fontSize: `clamp(14px, 5vw, 14px)`,
    fontWeight: 400,
    lineHeight: `120%`,
  },
  appCaption: {
    ...defaultColor,
    fontFamily: "var(--font-inter)",
    fontSize: `clamp(14px, 5vw, 14px)`,
    fontWeight: 400,
    lineHeight: `140%`,
  },
  appMetadata: {
    ...defaultColor,
    fontFamily: "var(--font-inter)",
    fontSize: `clamp(12px, 5vw, 12px)`,
    fontWeight: 400,
    lineHeight: `120%`,
  },
  appSubtitle: {
    ...defaultColor,
    fontFamily: "var(--font-inter)",
    fontSize: `clamp(20px, 5vw, 20px)`,
    fontWeight: 500,
    lineHeight: `140%`,
  },
  appSubtitleV2: {
    ...defaultColor,
    fontFamily: "var(--font-inter)",
    fontSize: `clamp(16px, 5vw, 16px)`,
    fontWeight: 400,
    lineHeight: `110%`,
  },
  appButtonText: {
    ...defaultColor,
    fontFamily: "var(--font-inter)",
    fontSize: `clamp(18px, 5vw, 18px)`,
    fontWeight: 600,
    lineHeight: `105%`,
  },
  appNavText: {
    ...defaultColor,
    fontFamily: "var(--font-inter)",
    fontSize: `clamp(16px, 5vw, 18px)`,
    lineHeight: "24px",
    fontWeight: 400,
  },

  h1: {
    fontFamily: "var(--font-k2d)",
    fontSize: `clamp(30px, 5vw, 40px)`,
    fontWeight: 700,
    lineHeight: `1.25em`,
    marginTop: "36px",
    marginBottom: "20px",
  },
  h2: {
    fontFamily: "var(--font-k2d)",
    fontSize: `clamp(16px, 4vw, 24px)`,
    fontWeight: 400,
    lineHeight: `1.25em`,
  },
  appH1: {
    fontFamily: "var(--font-k2d)",
    fontSize: 32,
    fontWeight: 500,
    lineHeight: `1.25em`,
    color: "black",
  },
  appH2: {
    fontSize: 24,
    fontWeight: k2dFontWeight.medium,
    lineHeight: `normal`,
    marginTop: 20,
    marginBottom: 15,
    display: "block",
    color: "black",
    fontFamily: "var(--font-k2d)",
  },
  appH3: {
    fontSize: 20,
    fontWeight: k2dFontWeight.medium,
    lineHeight: `1.25em`,
    marginTop: 0,
    marginBottom: 0,
    color: palette.black.i2,
    fontFamily: "var(--font-k2d)",
  },
  appH4: {
    fontSize: 16,
    fontWeight: k2dFontWeight.medium,
    lineHeight: `1.25em`,
    marginTop: 0,
    marginBottom: 0,
    color: "black",
    fontFamily: "var(--font-k2d)",
  },
  appSubtitleH3: {
    fontSize: 14,
    color: "#666666",
  },
  navigationActive: {
    fontFamily: "var(--font-k2d)",
    fontSize: 20,
    fontWeight: 500,
    color: palette.black.i2,
  },
  navigationInactive: {
    fontFamily: "var(--font-k2d)",
    fontSize: 20,
    fontWeight: 500,
    color: palette.white.i10,
  },
  formError: {
    fontWeight: 500,
    color: "#EA4335",
    marginTop: 5,
  },
  link: {
    ...defaultStyle,
    textDecoration: "underline",
  },
  standard: {
    ...defaultStyle,
    fontSize: 18,
  },
  secondary: {
    ...defaultStyle,
    color: palette.brand.grey.i3,
  },
  tertiary: {
    ...defaultStyle,
    color: palette.black.i1,
  },
  info: {
    ...defaultStyle,
    color: palette.brand.grey.i3,
  },
  body: {
    ...defaultStyle,
  },
};

export default typography;
