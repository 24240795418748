import React, { useState } from "react";
import { Divider } from "@components/common/Divider";
import { Box, Button, ListItemIcon, ListItemText, Menu, MenuItem, Typography } from "@mui/material";
import { ChevronIcon } from "@assets/icons/ChevronIcon";
import { SourceIcon } from "@assets/icons/SourceIcon";
import { PlatformEntity } from "@astronautchat/schema";
import Amplitude from "@services/Amplitude";
import { setRecoil } from "recoil-nexus";
import { activePlatformIdState, customerUserState, platformListState } from "@recoil/atoms";
import CustomerUser from "@services/CustomerUser";
import { useActiveSource } from "@hooks/useActiveSource";
import { useRecoilValue } from "recoil";
import { enqueueSnackbar } from "notistack";
import { Row } from "@components/common/Row";
import palette from "@styles/palette";
import { PlusSquareIcon } from "@assets/icons/PlusSquareIcon";
import { useRouter } from "next/router";

export const NavDropdown = ({ isCollapsed }: { isCollapsed: boolean }) => {
  const router = useRouter();
  const activeSource = useActiveSource();
  const sources = useRecoilValue(platformListState);
  const customerUser = useRecoilValue(customerUserState);

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const toggleSource = async (platformId: PlatformEntity["platformId"]) => {
    Amplitude.logEvent("Changed sources", { platformId });
    const isActive = activeSource.platformId === platformId;

    if (isActive) {
      return enqueueSnackbar({
        variant: "info",
        title: "You must have at least one active source.",
      });
    } else {
      setRecoil(activePlatformIdState, platformId);
      handleClose();

      await CustomerUser.updateActiveSource({
        customerUserId: customerUser!.customerUserId,
        platformId,
      });
    }
  };

  return (
    <Box sx={{ display: "flex", alignItems: "center", paddingX: 13 }}>
      <Button
        onClick={handleClick}
        sx={{
          display: "flex",
          width: "100%",
          backgroundColor: "#333",
          borderRadius: 8,
        }}
      >
        <Row
          display={"flex"}
          justifyContent="space-between"
          alignItems={"center"}
          width={"100%"}
        >
          <Row
            display={"flex"}
            justifyContent="space-between"
            alignItems={"center"}
            gap={10}
          >
            <Box
              sx={{
                background: palette.brand.neutral.light,
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                borderRadius: "50%",
                height: 30,
                width: 30,
              }}
            >
              <SourceIcon
                type={activeSource.platformType!}
                isActive={true}
                scheme="mono"
              />
            </Box>
            {!isCollapsed && (
              <Typography
                variant={"appNavText"}
                color={"#fff"}
              >
                {activeSource.platformName}
              </Typography>
            )}
          </Row>
          <ChevronIcon
            width={22}
            height={22}
            color="light"
            direction={"down"}
          />
        </Row>
      </Button>
      <Menu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleClose}
        sx={{
          "& .MuiMenu-list": {
            backgroundColor: "#333",
            border: `1px solid ${palette.brand.neutral.dark}`,
            width: "235px",
            marginTop: 2,
          },
          "& .MuiMenuItem-root": {
            width: "270px",
          },
        }}
      >
        {sources.map((item) => (
          <MenuItem
            key={item.platformId}
            onClick={() => toggleSource(item.platformId)}
            sx={{ paddingY: 8 }}
          >
            <ListItemIcon>
              <SourceIcon
                type={item.platformType}
                scheme="mono"
              />
            </ListItemIcon>
            <ListItemText>
              <Typography
                variant={"appNavText"}
                color={"#fff"}
                fontSize={16}
              >
                {item.name}
              </Typography>
            </ListItemText>
          </MenuItem>
        ))}
        <Divider
          color={palette.brand.neutral.dark}
          marginTop={8}
          marginBottom={6}
        />
        <MenuItem
          onClick={() => {
            Amplitude.logEvent("Pressed Add a Source button");
            router.push("/chat/settings/integrations");
          }}
        >
          <ListItemIcon>
            <PlusSquareIcon
              width={20}
              height={20}
            />
          </ListItemIcon>
          <ListItemText>
            <Typography
              variant={"appNavText"}
              color={"#fff"}
              fontSize={16}
            >
              Add a source
            </Typography>
          </ListItemText>
        </MenuItem>
      </Menu>
    </Box>
  );
};
