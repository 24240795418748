import { createTheme } from "@mui/material/styles";
import { muiComponents } from "./components";
import palette from "./palette";
import typography from "./typography";

const theme = createTheme({
  spacing: 1,
  shape: {
    borderRadius: 1,
  },
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 768,
      lg: 1200,
      xl: 1536,
    },
  },
  typography,
  components: muiComponents,
  palette,
});

export { theme };
