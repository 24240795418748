import { CustomerEntity } from "@astronautchat/schema";
import axios from "axios";
import authApi from "@api/authApi";
import { PaymentPeriod, PlanOptionName } from "@astronautchat/common-backend";

export default {
  checkout: async ({
    option,
    period,
    customerId,
    stripeCustomerId,
  }: {
    option: PlanOptionName;
    period: PaymentPeriod;
    customerId: CustomerEntity["customerId"];
    stripeCustomerId?: CustomerEntity["stripeCustomerId"];
  }): Promise<void> => {
    const res = await authApi.post("/payment", {
      option,
      period,
      customerId,
      stripeCustomerId,
    });
    const url = res.data?.url;

    if (url) {
      // @ts-ignore
      window.location.href = url;
    }
  },
  initFreeTrial: async ({
    customerId,
    stripeCustomerId,
  }: {
    customerId: CustomerEntity["customerId"];
    stripeCustomerId?: CustomerEntity["stripeCustomerId"];
  }): Promise<void> => {
    await axios.post("/api/trial", {
      customerId,
      stripeCustomerId,
    });
  },
};
