export const BODY_PADDING_HORIZONTAL = 22;
export const DASHBOARD_TOP_PADDING = 45;
export const DASHBOARD_MOBILE_TOP_PADDING = 30;
export const PAGE_PADDING_X = 40;
export const PAGE_PADDING_Y = 20;
export const TAG_COLOR_OPTIONS = [
  "#FF6900",
  "#FCB900",
  "#7BDCB5",
  "#00D084",
  "#8ED1FC",
  "#0693E3",
  "#ABB8C3",
  "#EB144C",
  "#F78DA7",
];
export const DEFAULT_TAG_COLOR = "#5875D3";
export const TAG_FORM_MAX_WIDTH = 1000;
export const NAV_CHAT_WIDTH = 270;
export const FORM_CONTAINER_GAP = 20;
