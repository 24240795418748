import { socketState } from "@recoil/utilityAtoms";
import createSocket from "@services/Socket";
import { getRecoil, setRecoil } from "recoil-nexus";
import { useEffect, useState } from "react";
import { demoState } from "@recoil/atoms";

export default function SocketProvider(props: any) {
  const [initialized, setInitialized] = useState<boolean>(false);
  const isInDemo = getRecoil(demoState);

  async function initSocket() {
    if (isInDemo) return;
    const initializedSocket = await createSocket();
    initializedSocket.connect();
    setRecoil(socketState, initializedSocket);

    return () => {
      initializedSocket.disconnect();
    };
  }

  useEffect(() => {
    initSocket().then(() => {
      setInitialized(true);
    });
  }, []);

  return initialized ? <>{props.children}</> : null;
}
