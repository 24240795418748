import { tabs } from "@assets/constants";
import { CenteredBox } from "@components/common/CenteredBox";
import { DEMO_CTA_HEIGHT } from "@components/DemoCTA";
import { CircularProgress } from "@mui/material";
import { styled } from "@mui/material/styles";
import { credentialsState, demoState } from "@recoil/atoms";
import { useRouter } from "next/router";
import { useRecoilValue } from "recoil";

const Container = styled("div")(
  ({ hasCottonCandy, isDemo }: { hasCottonCandy: boolean; isDemo: boolean }) => ({
    transition: "all 0.5s ease",
    backgroundImage: hasCottonCandy ? "url('/cotton-candy-1280x1600.jpg')" : "",
    backgroundPosition: "center",
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
    flexDirection: "row",
    position: "relative",
    display: "flex",
    minHeight: "-webkit-fill-available",
    height: "100%",
    maxHeight: isDemo ? `calc(100vh - ${DEMO_CTA_HEIGHT}px)` : "100vh",
    overflow: "visible",
    "@media (max-width: 768px)": {
      flexDirection: "column",
      alignItems: "center",
    },
  })
);

function detectRoute(pathname: string) {
  if (pathname.includes("/missions")) {
    return tabs.findIndex((item) => item.title === "Missions");
  } else if (pathname.includes("/activity")) {
    return tabs.findIndex((item) => item.title === "Activity");
  } else if (pathname.includes("/members")) {
    return tabs.findIndex((item) => item.title === "Members");
  } else if (pathname.includes("/settings")) {
    return tabs.findIndex((item) => item.title === "Settings");
  } else {
    return 0;
  }
}

const DashboardBody = styled("div")(() => ({
  width: "100%",
  height: "100%",
  overflow: "scroll",
  "@media (max-width: 768px)": {
    paddingLeft: 0,
    paddingRight: 0,
  },
}));

export default function Dashboard(props: {
  children: JSX.Element | JSX.Element[];
  NavigationComponent: JSX.Element;
}) {
  const router = useRouter();
  const loggedIn = !!useRecoilValue(credentialsState);
  const isDemo = useRecoilValue(demoState);

  // is there any way that this would ever be not true? Do we load this component for unauthorized users?
  return loggedIn ? (
    <Container
      hasCottonCandy={router.pathname === "/dashboard/missions"}
      isDemo={isDemo}
    >
      {props.NavigationComponent}
      <DashboardBody id="dashboard-body">{props.children}</DashboardBody>
    </Container>
  ) : (
    <Container
      hasCottonCandy={true}
      isDemo={isDemo}
    >
      <div
        style={{
          width: "100%",
          height: "100%",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <CenteredBox>
          <CircularProgress />
        </CenteredBox>
      </div>
    </Container>
  );
}
