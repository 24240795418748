export function LogoImage() {
  return (
    <svg
      width="20"
      height="25"
      viewBox="0 0 20 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g id="Group 7">
        <path
          id="Vector 9"
          d="M1.4093 4.9029C0.07124 7.33124 -1.33323e-05 9.25471 0 13.0955C7.6129e-06 19.8787 1.4093 21.4893 3.1903 23.1619C4.97129 24.8345 8.40936 25.2062 12.3895 24.4009C15.0687 23.8588 17.0278 22.4031 17.9183 20.5291C19.8317 16.5026 19.3121 9.98259 18.5533 6.94717C17.7944 3.91175 16.3231 2.61085 15.6727 2.00686C15.0223 1.40287 13.1019 0.287821 10.4536 0.1949C7.80538 0.101979 3.08189 1.86748 1.4093 4.9029Z"
          fill="#222222"
        />
        <path
          id="Ellipse 3"
          d="M16.9117 9.14431C16.9117 11.4997 16.1476 12.6472 14.8364 13.6524C13.5184 14.6629 11.648 14.9223 9.63283 14.9223C7.7636 14.9223 5.71823 14.5367 4.42924 13.6524C2.94611 12.6348 2.354 10.7867 2.354 9.14431C2.354 6.07451 5.61284 3.58594 9.63283 3.58594C13.6528 3.58594 16.9117 6.07451 16.9117 9.14431Z"
          fill="#DDDDD7"
        />
        <path
          id="Ellipse 4"
          d="M15.4448 7.48921C15.2855 8.10691 13.6385 7.13756 11.9132 6.69263C10.1879 6.24769 9.44385 5.62 9.60314 5.0023C9.76244 4.3846 11.5548 4.48875 13.2276 5.37827C14.8075 6.21837 15.6041 6.8715 15.4448 7.48921Z"
          fill="#222222"
        />
      </g>
    </svg>
  );
}
