import Image from "next/image";

export const StarIcon = ({
  width = 20,
  height = 20,
  color = "black",
}: {
  width?: number;
  height?: number;
  color: "black" | "pink" | "purple";
}) => (
  <Image
    alt="Star icon"
    src={
      color === "black"
        ? "/star-black.svg"
        : color === "pink"
        ? "/star-pink.svg"
        : "/star-purple.svg"
    }
    width={width}
    height={height}
    priority
    style={{ objectFit: "contain", width }}
    sizes={`(max-width: 768px) ${width}px, (max-width: 1200px) ${width}px, ${width}px`}
  />
);
