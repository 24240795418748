import {
  CustomerRoleValue,
  PlatformTypeValue,
  PromptVersionValue,
  SubscriptionStatusValue,
} from "@astronautchat/schema";
import { rolesResponse } from "./roles";
import { GetMeAPI } from "@astronautchat/common-backend";

export const discordPlatformId = "discord-plat-id";
export const slackPlatformId = "slack-plat-id";
export const myUserId = "demo-user-id";
export const myCustomerUserId = "demo-customer-user-id";
export const myCustomerId = "demo-customer-id";
export const discordCommunityId = "demo-discord-community-id";
export const slackCommunityId = "demo-slack-community-id";
export const demoMemberId = "demo-member-id";
export const conversationId1 = "conversation-id-111";
export const conversationId2 = "conversation-id-222";

export const conversationsResponse = [
  {
    conversationId: conversationId1,
    version: PromptVersionValue.version5,
    userId: myUserId,
    createdAt: new Date("2024-04-24T23:38:18.999Z"),
    updatedAt: new Date(new Date("2024-04-24T23:38:18.999Z")),
    deletedAt: null,
    isInternal: false,
    platformId: discordPlatformId,
    title: "What are some good options for new user rewards?",
  },
  {
    conversationId: conversationId2,
    version: PromptVersionValue.version5,
    userId: myUserId,
    createdAt: new Date("2024-04-24T23:38:18.999Z"),
    updatedAt: new Date("2024-04-24T23:38:18.999Z"),
    deletedAt: null,
    isInternal: false,
    platformId: discordPlatformId,
    title: "What is the general sentiment from our members?",
  },
];

export const channelsResponse: Record<string, any> = {
  [discordPlatformId]: [
    {
      platformType: "discord",
      platformId: discordPlatformId,
      discordChannelId: "test1",
      platformDiscordId: discordPlatformId,
      channelId: "1111",
      name: "💬 | general",
      type: 0,
      isNSFW: false,
      parentId: "0000",
      topic: null,
      messageCount: 1,
      position: 100,
      firstMessageDate: new Date("2024-04-23T17:48:48.143Z"),
      hasFetchedHistory: true,
      isIntroChannel: false,
      isConnectionChannel: false,
      isDeleted: false,
      createdAt: new Date("2024-04-23T17:48:48.201Z"),
      updatedAt: new Date("2024-04-23T17:48:48.201Z"),
      deletedAt: null,
      hasPermission: true,
      shouldAnalyze: true,
    },
    {
      platformType: "discord",
      platformId: discordPlatformId,
      discordChannelId: "test2",
      platformDiscordId: discordPlatformId,
      channelId: "2222",
      name: "💻 | support",
      type: 0,
      isNSFW: false,
      parentId: "0000",
      topic: null,
      messageCount: 1,
      position: 100,
      firstMessageDate: new Date("2024-04-23T17:48:48.143Z"),
      hasFetchedHistory: true,
      isIntroChannel: false,
      isConnectionChannel: false,
      isDeleted: false,
      createdAt: new Date("2024-04-23T17:48:48.201Z"),
      updatedAt: new Date("2024-04-23T17:48:48.201Z"),
      deletedAt: null,
      hasPermission: true,
      shouldAnalyze: true,
    },
    {
      platformType: "discord",
      platformId: discordPlatformId,
      discordChannelId: "test3",
      platformDiscordId: discordPlatformId,
      channelId: "3333",
      name: "⌨️ | hackathons",
      type: 0,
      isNSFW: false,
      parentId: "0000",
      topic: null,
      messageCount: 1,
      position: 100,
      firstMessageDate: new Date("2024-04-23T17:48:48.143Z"),
      hasFetchedHistory: true,
      isIntroChannel: false,
      isConnectionChannel: false,
      isDeleted: false,
      createdAt: new Date("2024-04-23T17:48:48.201Z"),
      updatedAt: new Date("2024-04-23T17:48:48.201Z"),
      deletedAt: null,
      hasPermission: true,
      shouldAnalyze: true,
    },
    {
      platformType: "discord",
      platformId: discordPlatformId,
      discordChannelId: "test4",
      platformDiscordId: discordPlatformId,
      channelId: "4444",
      name: "🗣️ | off-topic",
      type: 0,
      isNSFW: false,
      parentId: "0000",
      topic: null,
      messageCount: 1,
      position: 100,
      firstMessageDate: new Date("2024-04-23T17:48:48.143Z"),
      hasFetchedHistory: true,
      isIntroChannel: false,
      isConnectionChannel: false,
      isDeleted: false,
      createdAt: new Date("2024-04-23T17:48:48.201Z"),
      updatedAt: new Date("2024-04-23T17:48:48.201Z"),
      deletedAt: null,
      hasPermission: true,
      shouldAnalyze: true,
    },
    {
      platformType: "discord",
      platformId: discordPlatformId,
      discordChannelId: "test5",
      platformDiscordId: discordPlatformId,
      channelId: "4444",
      name: "👋 | intros",
      type: 0,
      isNSFW: false,
      parentId: "0000",
      topic: null,
      messageCount: 1,
      position: 100,
      firstMessageDate: new Date("2024-04-23T17:48:48.143Z"),
      hasFetchedHistory: true,
      isIntroChannel: false,
      isConnectionChannel: false,
      isDeleted: false,
      createdAt: new Date("2024-04-23T17:48:48.201Z"),
      updatedAt: new Date("2024-04-23T17:48:48.201Z"),
      deletedAt: null,
      hasPermission: true,
      shouldAnalyze: true,
    },
    {
      platformType: "discord",
      platformId: discordPlatformId,
      discordChannelId: "test6",
      platformDiscordId: discordPlatformId,
      channelId: "4444",
      name: "📣 | announcements",
      type: 0,
      isNSFW: false,
      parentId: "0000",
      topic: null,
      messageCount: 1,
      position: 100,
      firstMessageDate: new Date("2024-04-23T17:48:48.143Z"),
      hasFetchedHistory: true,
      isIntroChannel: false,
      isConnectionChannel: false,
      isDeleted: false,
      createdAt: new Date("2024-04-23T17:48:48.201Z"),
      updatedAt: new Date("2024-04-23T17:48:48.201Z"),
      deletedAt: null,
      hasPermission: true,
      shouldAnalyze: true,
    },
  ],
  [slackPlatformId]: [
    {
      platformType: "slack",
      platformId: slackPlatformId,
      slackChannelId: "stest1",
      platformSlackId: slackPlatformId,
      channelId: "1111",
      name: "🎨 | product",
      type: 0,
      isNSFW: false,
      parentId: "0000",
      topic: null,
      messageCount: 1,
      position: 100,
      firstMessageDate: new Date("2024-04-23T17:48:48.143Z"),
      hasFetchedHistory: true,
      isIntroChannel: false,
      isConnectionChannel: false,
      isDeleted: false,
      createdAt: new Date("2024-04-23T17:48:48.201Z"),
      updatedAt: new Date("2024-04-23T17:48:48.201Z"),
      deletedAt: null,
      hasPermission: true,
      shouldAnalyze: true,
    },
    {
      platformType: "slack",
      platformId: slackPlatformId,
      slackChannelId: "stest2",
      platformSlackId: slackPlatformId,
      channelId: "2222",
      name: "🤪 | random",
      type: 0,
      isNSFW: false,
      parentId: "0000",
      topic: null,
      messageCount: 1,
      position: 100,
      firstMessageDate: new Date("2024-04-23T17:48:48.143Z"),
      hasFetchedHistory: true,
      isIntroChannel: false,
      isConnectionChannel: false,
      isDeleted: false,
      createdAt: new Date("2024-04-23T17:48:48.201Z"),
      updatedAt: new Date("2024-04-23T17:48:48.201Z"),
      deletedAt: null,
      hasPermission: true,
      shouldAnalyze: true,
    },
    {
      platformType: "slack",
      platformId: slackPlatformId,
      slackChannelId: "stest3",
      platformSlackId: slackPlatformId,
      channelId: "3333",
      name: "🗒️ | feedback",
      type: 0,
      isNSFW: false,
      parentId: "0000",
      topic: null,
      messageCount: 1,
      position: 100,
      firstMessageDate: new Date("2024-04-23T17:48:48.143Z"),
      hasFetchedHistory: true,
      isIntroChannel: false,
      isConnectionChannel: false,
      isDeleted: false,
      createdAt: new Date("2024-04-23T17:48:48.201Z"),
      updatedAt: new Date("2024-04-23T17:48:48.201Z"),
      deletedAt: null,
      hasPermission: true,
      shouldAnalyze: true,
    },
    {
      platformType: "slack",
      platformId: slackPlatformId,
      slackChannelId: "stest4",
      platformSlackId: slackPlatformId,
      channelId: "3333",
      name: "📣 | announcements",
      type: 0,
      isNSFW: false,
      parentId: "0000",
      topic: null,
      messageCount: 1,
      position: 100,
      firstMessageDate: new Date("2024-04-23T17:48:48.143Z"),
      hasFetchedHistory: true,
      isIntroChannel: false,
      isConnectionChannel: false,
      isDeleted: false,
      createdAt: new Date("2024-04-23T17:48:48.201Z"),
      updatedAt: new Date("2024-04-23T17:48:48.201Z"),
      deletedAt: null,
      hasPermission: true,
      shouldAnalyze: true,
    },
    {
      platformType: "slack",
      platformId: slackPlatformId,
      slackChannelId: "stest5",
      platformSlackId: slackPlatformId,
      channelId: "3333",
      name: "🐛 | bugs",
      type: 0,
      isNSFW: false,
      parentId: "0000",
      topic: null,
      messageCount: 1,
      position: 100,
      firstMessageDate: new Date("2024-04-23T17:48:48.143Z"),
      hasFetchedHistory: true,
      isIntroChannel: false,
      isConnectionChannel: false,
      isDeleted: false,
      createdAt: new Date("2024-04-23T17:48:48.201Z"),
      updatedAt: new Date("2024-04-23T17:48:48.201Z"),
      deletedAt: null,
      hasPermission: true,
      shouldAnalyze: true,
    },
  ],
};

export function flattenObjectValues<T>(obj: Record<string, T[]>): T[] {
  return Object.values(obj).reduce((acc, val) => acc.concat(val), []);
}

export const meResponse: GetMeAPI = {
  userId: myUserId,
  email: "support@astronaut.chat",
  firstName: "Demo",
  lastName: "User",
  token: "nwe2wucozyid",
  session: null,
  sub: "f5a97f5d-6fbf-4341-8cd1-7d2db3800713",
  avatarUrl: null,
  timezone: null,
  hasConfirmedCode: true,
  receiveAnnouncements: true,
  receiveReports: true,
  receiveTelegramAnnouncement: true,
  receiveSlackAnnouncement: true,
  createdAt: new Date("2024-04-23T13:16:28.055Z"),
  updatedAt: new Date("2024-04-23T13:43:46.639Z"),
  deletedAt: null,
  tags: [],
  customerUsers: [
    {
      customerUserId: myCustomerUserId,
      customerId: myCustomerId,
      userId: myUserId,
      role: CustomerRoleValue.admin,
      memberId: demoMemberId,
      createdAt: new Date("2024-04-23T13:16:28.075Z"),
      updatedAt: new Date("2024-04-23T13:16:28.075Z"),
      deletedAt: null,
      member: {
        memberId: demoMemberId,
        firstName: null,
        lastName: null,
        email: "support@astronaut.chat",
        phone: null,
        city: null,
        state: null,
        country: null,
        about: null,
        createdAt: new Date("2024-04-23T13:16:28.061Z"),
        updatedAt: new Date("2024-04-23T13:16:28.061Z"),
        deletedAt: null,
        communityMembers: [],
      },
      customer: {
        customerId: myCustomerId,
        name: "support@astronaut.chat",
        email: "support@astronaut.chat",
        hasIntegratedBot: true,
        hasIntegratedSlackBot: true,
        stripeCustomerId: "cus_fake",
        address: null,
        phone: null,
        subscriptionInterval: "month",
        subscriptionStatus: SubscriptionStatusValue.active,
        amountPaid: 9000,
        currency: "usd",
        planId: process.env.NEXT_PUBLIC_STRIPE_STARTER_MONTHLY_PRICE_ID!,
        productId: "prod_2134",
        subscriptionId: "sub_1234",
        subscriptionItemId: "sub_1234",
        paidAt: 0,
        trialStart: null,
        trialEnd: null,
        currentPeriodStart: null,
        currentPeriodEnd: null,
        cancelAtPeriodEnd: false,
        hasTrialed: true,
        createdAt: new Date("2024-04-23T13:16:28.070Z"),
        updatedAt: new Date("2024-04-23T13:16:28.070Z"),
        deletedAt: null,
        communities: [
          {
            communityId: discordCommunityId,
            customerId: myCustomerId,
            name: "Demo Community",
            logoUrl: null,
            createdAt: new Date("2024-04-23T13:44:00.079Z"),
            updatedAt: new Date("2024-04-23T13:44:00.079Z"),
            deletedAt: null,
            platforms: [
              {
                platformId: discordPlatformId,
                communityId: discordCommunityId,
                platformType: PlatformTypeValue.discord,
                name: "Cosmos AI",
                description: null,
                memberCount: 2,
                hasCompletedIntegration: true,
                hasCompletedOnboarding: true,
                isSyncing: false,
                isDisconnected: false,
                createdAt: new Date("2024-04-23T13:48:48.183Z"),
                updatedAt: new Date("2024-04-23T13:48:48.183Z"),
                deletedAt: null,
                platformDiscord: {
                  platformDiscordId: "m91kkgh38ql4dnkr12un0rjn",
                  platformId: discordPlatformId,
                  guildId: "1232326662570508430",
                  iconHash: null,
                  hasFetchedRoles: true,
                  hasFetchedChannels: false,
                  hasReadMessagesPermission: true,
                  hasReadMessageHistoryPermission: true,
                  hasSendMessagePermission: true,
                  hasSendMessageInThreadsPermission: true,
                  hasAuditLogPermission: true,
                  hasEmbedLinkPermission: true,
                  hasMentionPermission: true,
                  hasAddReactionPermission: true,
                  hasManageChannelsPermission: false,
                  hasManageRolesPermission: false,
                  hasManageGuildPermission: false,
                  createdAt: new Date("2024-04-23T13:48:48.188Z"),
                  updatedAt: new Date("2024-04-23T13:48:48.188Z"),
                  deletedAt: null,
                },
                platformTelegram: null,
                platformSlack: null,
                customerUserPlatform: [
                  {
                    customerUserId: myCustomerUserId,
                    platformId: discordPlatformId,
                    isActive: true,
                    createdAt: new Date("2024-04-23T13:48:51.112Z"),
                    updatedAt: new Date("2024-04-23T13:48:51.112Z"),
                    deletedAt: null,
                  },
                ],
              },
              {
                platformId: slackPlatformId,
                communityId: slackCommunityId,
                platformType: PlatformTypeValue.slack,
                name: "Cosmos AI",
                description: null,
                memberCount: 0,
                hasCompletedIntegration: true,
                hasCompletedOnboarding: true,
                isSyncing: false,
                isDisconnected: false,
                createdAt: new Date("2024-02-19T21:23:02.207Z"),
                updatedAt: new Date("2024-02-19T21:23:02.207Z"),
                deletedAt: null,
                platformDiscord: null,
                platformTelegram: null,
                platformSlack: {
                  platformSlackId: "s9pocbpv8ue1nh8vtrnqnhhn",
                  platformId: slackPlatformId,
                  teamId: "slack-team-id",
                  domain: "astronautdemo",
                  iconUrl:
                    "https://avatars.slack-edge.com/2023-12-22/6381812013318_08913f6bfd05d573d49b_230.png",
                  accessToken: null,
                  refreshToken: null,
                  installData: {
                    bot: {
                      id: "fake-id",
                      token: "xoxb-fake-slack",
                      scopes: [
                        "chat:write",
                        "channels:history",
                        "groups:history",
                        "im:history",
                        "mpim:history",
                        "channels:read",
                        "groups:read",
                        "team:read",
                        "links:read",
                        "mpim:read",
                        "metadata.message:read",
                        "pins:read",
                        "reactions:read",
                        "users:read",
                      ],
                      userId: "fake-id",
                    },
                    team: {
                      id: "fake-id",
                      name: "Cosmos AI",
                    },
                    user: {
                      id: "fake-id",
                    },
                    appId: "fake-id",
                    tokenType: "bot",
                    authVersion: "v2",
                    isEnterpriseInstall: false,
                  },
                  hasFetchedChannels: false,
                  createdAt: new Date("2024-02-19T21:23:02.227Z"),
                  updatedAt: new Date("2024-02-19T21:23:02.227Z"),
                  deletedAt: null,
                },
                customerUserPlatform: [
                  {
                    customerUserId: myCustomerUserId,
                    platformId: slackPlatformId,
                    isActive: true,
                    createdAt: new Date("2024-04-23T13:48:51.112Z"),
                    updatedAt: new Date("2024-04-23T13:48:51.112Z"),
                    deletedAt: null,
                  },
                ],
              },
            ],
            missions: [],
            tags: [
              {
                tagId: "demo-tag-1",
                communityId: discordCommunityId,
                name: "All members",
                color: "#222222",
                isAutoAssigned: false,
                description: null,
                autoAssignCount: 0,
                persistToDiscord: false,
                persistToSlack: false,
                createdAt: new Date("2024-04-23T13:48:51.119Z"),
                updatedAt: new Date("2024-04-23T13:48:51.119Z"),
                deletedAt: null,
                _count: {
                  communityMemberTags: 1,
                },
              },
            ],
          },
        ],
      },
    },
  ],
  channels: flattenObjectValues(channelsResponse),
  conversations: conversationsResponse,
  groups: rolesResponse(discordPlatformId),
};
