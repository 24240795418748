import { Plan, PlanOption } from "@astronautchat/common-backend";
import { UserEntity } from "@astronautchat/schema";
import { Tab } from "@custom-types/navigation";

type TabProps = {
  title: Tab;
  activeIconUrl: string;
  inactiveIconUrl: string;
  backgroundGradients: string[];
  inactiveIconNotificationUrl?: string;
  isCollapsed: boolean;
};

export const tabs: TabProps[] = [
  {
    title: "Missions",
    activeIconUrl: "/icons/missions.svg",
    inactiveIconUrl: "/icons/missions-inactive.svg",
    backgroundGradients: ["rgba(158,240,223,1)", "rgba(144,224,165,1)"],
    isCollapsed: false,
  },
  {
    title: "Activity",
    activeIconUrl: "/icons/activity.svg",
    inactiveIconUrl: "/icons/activity-inactive.svg",
    inactiveIconNotificationUrl: "/icons/activity-inactive-notification.svg",
    backgroundGradients: ["#FFD9CE", "#EE928A"],
    isCollapsed: false,
  },
  {
    title: "Members",
    activeIconUrl: "/icons/members.svg",
    inactiveIconUrl: "/icons/members-inactive.svg",
    backgroundGradients: ["rgba(239,193,182,1)", "rgba(233,162,228,1)"],
    isCollapsed: true,
  },
  {
    title: "Settings",
    activeIconUrl: "/icons/settings.svg",
    inactiveIconUrl: "/icons/settings-inactive.svg",
    backgroundGradients: ["rgba(236,249,173,1)", "rgba(238,228,144,1)"],
    isCollapsed: false,
  },
];

export const HORIZONTAL_PAGE_PADDING = 27;

export const defaultConnectTemplate = {
  blocks: [
    {
      key: "6bomv",
      text: "{connect_member}, meet {to_member}. {why}. Find some time to chat here: {scheduler}",
      type: "unstyled",
      depth: 0,
      inlineStyleRanges: [],
      entityRanges: [
        {
          offset: 0,
          length: 16,
          key: 0,
        },
        {
          offset: 23,
          length: 11,
          key: 1,
        },
        {
          offset: 36,
          length: 5,
          key: 2,
        },
        {
          offset: 73,
          length: 11,
          key: 3,
        },
      ],
      data: {},
    },
  ],
  entityMap: {
    "0": {
      type: "{mention",
      mutability: "IMMUTABLE",
      data: {
        mention: {
          name: "{connect_member}",
          description: "A member from the Connect group.",
        },
      },
    },
    "1": {
      type: "{mention",
      mutability: "IMMUTABLE",
      data: {
        mention: {
          name: "{to_member}",
          description: "A member from the To group.",
        },
      },
    },
    "2": {
      type: "{mention",
      mutability: "IMMUTABLE",
      data: {
        mention: {
          name: "{why}",
          description: "The reason these two members are being connected.",
        },
      },
    },
    "3": {
      type: "{mention",
      mutability: "IMMUTABLE",
      data: {
        mention: {
          name: "{scheduler}",
          description: "URL for scheduling the connection.",
        },
      },
    },
  },
};

export const DEFAULT_AVATAR_URL = "/logo.png";

export const DEV_EMAILS: UserEntity["email"][] = [
  "cvolzer3@gmail.com",
  "chris@astronaut.chat",
  "mdurham.design@gmail.com",
];

export const INTERNAL_USER_EMAILS: UserEntity["email"][] = [
  "cvolzer3@gmail.com",
  "ayo.johnsu@gmail.com",
  "ayoola@astronaut.chat",
  "chris@astronaut.chat",
  "victoria@astronaut.chat",
  "design@joshuabutts.com",
  "mdurham.design@gmail.com",
];

export const AUTH_OVERRIDE_EMAILS: UserEntity["email"][] = ["hey@astronaut.chat"];

export const NON_ADMIN_INTERNAL_EMAILS: UserEntity["email"][] = ["design@joshuabutts.com"];

export const timezones = [
  // { long: "(GMT -02:00) America/Noronha", short: "FNT", location: "America/Noronha" },
  // { long: "(GMT -03:00) America/Sao_Paulo", short: "BRT", location: "America/Sao_Paulo" },
  { long: "(GMT -04:00) America/New_York", short: "ET", location: "America/New_York" },
  { long: "(GMT -05:00) America/Chicago", short: "CT", location: "America/Chicago" },
  { long: "(GMT -06:00) America/Denver", short: "MT", location: "America/Denver" },
  { long: "(GMT -07:00) America/Los_Angeles", short: "PT", location: "America/Los_Angeles" },
  { long: "(GMT -08:00) America/Anchorage", short: "AKT", location: "America/Anchorage" },
  { long: "(GMT +00:00) Europe/London", short: "London", location: "Europe/London" },
  { long: "(GMT +01:00) Europe/Berlin", short: "CET", location: "Europe/Berlin" },
  { long: "(GMT +02:00) Europe/Athens", short: "EET", location: "Europe/Athens" },
  { long: "(GMT +03:00) Europe/Moscow", short: "MSK", location: "Europe/Moscow" },
  { long: "(GMT +03:30) Asia/Tehran", short: "IRST", location: "Asia/Tehran" },
  { long: "(GMT +04:00) Asia/Dubai", short: "GST", location: "Asia/Dubai" },
  { long: "(GMT -10:00) Pacific/Honolulu", short: "HST", location: "Pacific/Honolulu" },
  { long: "(GMT -11:00) Pacific/Midway", short: "SST", location: "Pacific/Midway" },
  { long: "(GMT +00:00) Africa/Accra", short: "GMT+0", location: "Africa/Accra" },
  { long: "(GMT +04:30) Asia/Kabul", short: "AFT", location: "Asia/Kabul" },
  { long: "(GMT +05:00) Asia/Karachi", short: "PKT", location: "Asia/Karachi" },
  { long: "(GMT +05:30) Asia/Kolkata", short: "IST", location: "Asia/Kolkata" },
  { long: "(GMT +05:45) Asia/Kathmandu", short: "NPT", location: "Asia/Kathmandu" },
  { long: "(GMT +06:00) Asia/Dhaka", short: "BST", location: "Asia/Dhaka" },
  { long: "(GMT +06:30) Asia/Yangon", short: "MMT", location: "Asia/Yangon" },
  { long: "(GMT +07:00) Asia/Bangkok", short: "ICT", location: "Asia/Bangkok" },
  { long: "(GMT +08:00) Asia/Singapore", short: "SGT", location: "Asia/Singapore" },
  { long: "(GMT +09:00) Asia/Tokyo", short: "JST", location: "Asia/Tokyo" },
  { long: "(GMT +09:30) Australia/Darwin", short: "ACST", location: "Australia/Darwin" },
  { long: "(GMT +10:00) Australia/Sydney", short: "AEST", location: "Australia/Sydney" },
  { long: "(GMT +10:30) Australia/Adelaide", short: "ACDT", location: "Australia/Adelaide" },
  { long: "(GMT +11:00) Pacific/Noumea", short: "NCT", location: "Pacific/Noumea" },
  { long: "(GMT +12:00) Pacific/Auckland", short: "NZST", location: "Pacific/Auckland" },
  { long: "(GMT +13:00) Pacific/Tongatapu", short: "TOT", location: "Pacific/Tongatapu" },
  { long: "(GMT -01:00) Atlantic/Cape_Verde", short: "CVT", location: "Atlantic/Cape_Verde" },
];

export const plans: Plan[] = [
  {
    title: "Starter",
    name: "starter",
    body: "Get Astronaut’s AI sentiment analysis with basic insight reports.",
    monthlyPrice: 90,
    annualPrice: 75,
    unit: "per seat per month",
    isTrial: false,
    features: ["1 integration", "1 seat included", "Insights Reports", "Unlimited AI Chat"],
    order: 1,
  },
  {
    title: "Pro",
    name: "pro",
    body: "Integrate up to 3 sources. 3 seats included. $90 per additional seat.",
    monthlyPrice: 270,
    annualPrice: 225,
    unit: "per month",
    isTrial: false,
    features: ["3 integrations", "3 seats included", "Insights Reports", "Unlimited AI Chat"],
    order: 2,
  },
  {
    title: "Enterprise",
    name: "enterprise",
    body: "Get unlimited integrations, advanced insight reports, and priority support.",
    monthlyPrice: "Custom",
    annualPrice: "Custom",
    unit: "",
    isTrial: false,
    features: [
      "Includes <b>5</b> seats",
      "Integrate <b>unlimited</b> sources",
      "<b>Advanced</b> insight reports",
      "<b>Priority</b> support",
    ],
    order: 3,
  },
];

export const planSeatsIncluded = {
  starter: 1,
  pro: 3,
  enterprise: 5,
};

export const planOptions: PlanOption = {
  starter: {
    monthly: {
      priceId: process.env.NEXT_PUBLIC_STRIPE_STARTER_MONTHLY_PRICE_ID! as string,
      extraSeatPriceId: process.env.STRIPE_STARTER_MONTHLY_SEAT_PRICE_ID! as string,
    },
    month3: {
      priceId: process.env.NEXT_PUBLIC_STRIPE_STARTER_3MO_PRICE_ID! as string,
    },
    month6: {
      priceId: process.env.NEXT_PUBLIC_STRIPE_STARTER_6MO_PRICE_ID! as string,
    },
    annual: {
      priceId: process.env.NEXT_PUBLIC_STRIPE_STARTER_ANNUAL_PRICE_ID! as string,
      extraSeatPriceId: process.env.STRIPE_STARTER_ANNUAL_SEAT_PRICE_ID! as string,
    },
  },
  pro: {
    monthly: {
      priceId: process.env.NEXT_PUBLIC_STRIPE_PRO_MONTHLY_PRICE_ID! as string,
      extraSeatPriceId: process.env.STRIPE_PRO_MONTHLY_SEAT_PRICE_ID! as string,
    },
    month3: {
      priceId: process.env.NEXT_PUBLIC_STRIPE_PRO_3MO_PRICE_ID! as string,
    },
    month6: {
      priceId: process.env.NEXT_PUBLIC_STRIPE_PRO_6MO_PRICE_ID! as string,
    },
    annual: {
      priceId: process.env.NEXT_PUBLIC_STRIPE_PRO_ANNUAL_PRICE_ID! as string,
      extraSeatPriceId: process.env.STRIPE_PRO_ANNUAL_SEAT_PRICE_ID! as string,
    },
  },
  enterprise: {
    monthly: {
      priceId: process.env.NEXT_PUBLIC_STRIPE_ENTERPRISE_MONTHLY_PRICE_ID! as string,
    },
    month3: {
      priceId: process.env.NEXT_PUBLIC_STRIPE_ENTERPRISE_ANNUAL_PRICE_ID! as string,
    },
    month6: {
      priceId: process.env.NEXT_PUBLIC_STRIPE_ENTERPRISE_ANNUAL_PRICE_ID! as string,
    },
    annual: {
      priceId: process.env.NEXT_PUBLIC_STRIPE_ENTERPRISE_ANNUAL_PRICE_ID! as string,
    },
  },
};

export const customerConfigs = {
  sudowrite: {
    apiKey: "7t6uyihojphgifiyugibnnkjasdad",
    customerId: "f2jt6nhjhnuzc53pqv86w3rj",
  },
  medal: {
    apiKey: "uhyugt7r68gh9uoijmoiuyihjokjd",
    customerId: "j5h66l22s31vsze2pqtxukin",
  },
};
