import Image from "next/image";
import Link from "next/link";

const ratio = 150 / 44;

export const Wordmark = ({
  width = 150,
  color,
  link,
}: {
  width?: number;
  color: "black" | "white" | "grey";
  link?: string;
}) => {
  const src =
    color === "black"
      ? "/wordmark-black.svg"
      : color === "white"
      ? "/wordmark-white.svg"
      : "/wordmark-grey.svg";

  const height = width / ratio;

  if (link) {
    return (
      <Link href={link}>
        <Image
          alt="Astronaut wordmark"
          src={src}
          width={width}
          height={height}
          priority
          style={{ objectFit: "contain" }}
          sizes={`(max-width: 768px) ${width}px, (max-width: 1200px) ${width}px, ${width}px`}
        />
      </Link>
    );
  }

  return (
    <Image
      alt="Astronaut wordmark"
      src={src}
      width={width}
      height={height}
      priority
      style={{ objectFit: "contain" }}
      sizes={`(max-width: 768px) ${width}px, (max-width: 1200px) ${width}px, ${width}px`}
    />
  );
};
