import "@code-hike/mdx/dist/index.css";
import DemoCTA from "@components/DemoCTA";
import { ErrorSnackbar } from "@components/snackbar/ErrorSnackbar";
import { InfoSnackbar } from "@components/snackbar/InfoSnackbar";
import { SuccessSnackbar } from "@components/snackbar/SuccessSnackbar";
import "@draft-js-plugins/mention/lib/plugin.css";
import { CssBaseline, ThemeProvider } from "@mui/material";
import AdminProvider from "@providers/AdminProvider";
import AuthProvider from "@providers/AuthProvider";
import { DemoProvider } from "@providers/DemoProvider";
import SocketProvider from "@providers/SocketProvider";
import "@styles/bg.css";
import "@styles/bounce.css";
import { NAV_CHAT_WIDTH } from "@styles/constants";
import "@styles/draftjs.css";
import "@styles/fadeIn.css";
import "@styles/globals.css";
import palette from "@styles/palette";
import "@styles/pulse.css";
import { theme } from "@styles/theme";
import "@styles/wave.css";
import AnalyticsProvider from "@templates/AnalyticsProvider";
import Dashboard from "@templates/Dashboard";
import ErrorBoundary from "@templates/ErrorBoundary";
import { NavChat } from "@templates/Navigation/NavChat";
import { NavContainer } from "@templates/Navigation/NavContainer";
import { NavSettings } from "@templates/Navigation/NavSettings";
import Paywall from "@templates/Paywall";
import "draft-js/dist/Draft.css";
import type { AppProps } from "next/app";
import { Inter, K2D } from "next/font/google";
import { useRouter } from "next/router";
import { SnackbarProvider } from "notistack";
import { QueryClient, QueryClientProvider } from "react-query";
import { RecoilRoot } from "recoil";
import RecoilNexus from "recoil-nexus";
import "tippy.js/dist/tippy.css";

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
    },
  },
});
const k2d = K2D({
  subsets: ["latin"],
  weight: ["100", "200", "300", "400", "500", "600", "700", "800"],
});
const inter = Inter({
  subsets: ["latin"],
  weight: ["100", "400", "500", "600", "700"],
});

const FontStyle = () => (
  <style
    jsx
    global
  >{`
    :root {
      --font-k2d: ${k2d.style.fontFamily};
      --font-inter: ${inter.style.fontFamily};
    }
  `}</style>
);

const BodyStyle = () => (
  <style
    jsx
    global
  >{`
    body {
      background: #f3f3ef;
    }
  `}</style>
);

export default function App({ Component, pageProps: { session, ...pageProps } }: AppProps) {
  const router = useRouter();

  const isAdminPage = router.pathname.includes("/admin");
  const isChatPage = router.pathname.includes("/chat");

  const isReportsPage = router.pathname.includes("/chat/reports");

  const isPromptOnboarding = router.pathname.includes("/o/") && router.pathname !== "/o/inbox";

  const isChatSettingsPage = router.pathname.includes("/chat/settings");

  const isCustomerPage =
    router.pathname.includes("/sudowrite") || router.pathname.includes("/medal");

  return (
    <QueryClientProvider client={queryClient}>
      <SnackbarProvider
        Components={{
          error: ErrorSnackbar,
          info: InfoSnackbar,
          success: SuccessSnackbar,
        }}
        anchorOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        autoHideDuration={3000}
      >
        <ThemeProvider theme={theme}>
          <RecoilRoot>
            <CssBaseline />
            <FontStyle />
            <ErrorBoundary>
              <RecoilNexus />
              <DemoProvider>
                <main
                  style={{
                    display: "flex",
                    flexDirection: "column",
                  }}
                  className={k2d.className}
                >
                  <DemoCTA />
                  <AnalyticsProvider>
                    {isAdminPage ? (
                      <AuthProvider type="intro">
                        <AdminProvider>
                          <Component {...pageProps} />
                        </AdminProvider>
                      </AuthProvider>
                    ) : isChatPage && !isChatSettingsPage && !isReportsPage ? (
                      <AuthProvider type="chat">
                        <Dashboard
                          NavigationComponent={
                            <NavContainer
                              color={palette.black.i2}
                              collapsedWidth={95}
                            >
                              <NavChat />
                            </NavContainer>
                          }
                        >
                          <SocketProvider>
                            <Component {...pageProps} />
                          </SocketProvider>
                        </Dashboard>
                      </AuthProvider>
                    ) : isChatPage && isReportsPage ? (
                      <AuthProvider type="chat">
                        <Dashboard
                          NavigationComponent={
                            <NavContainer
                              color={palette.black.i2}
                              collapsedWidth={95}
                            >
                              <NavChat />
                            </NavContainer>
                          }
                        >
                          <SocketProvider>
                            <Paywall>
                              <Component {...pageProps} />
                            </Paywall>
                          </SocketProvider>
                        </Dashboard>
                      </AuthProvider>
                    ) : isChatPage && isChatSettingsPage ? (
                      <AuthProvider type="chat">
                        <Dashboard
                          NavigationComponent={
                            <NavContainer
                              color={"white"}
                              collapsedWidth={NAV_CHAT_WIDTH} // no collapsed state
                            >
                              <NavSettings />
                            </NavContainer>
                          }
                        >
                          <SocketProvider>
                            <Component {...pageProps} />
                          </SocketProvider>
                        </Dashboard>
                      </AuthProvider>
                    ) : isPromptOnboarding ? (
                      <AuthProvider type="chat">
                        <BodyStyle />
                        <Component {...pageProps} />
                      </AuthProvider>
                    ) : isCustomerPage ? (
                      <AuthProvider type="chat">
                        <BodyStyle />
                        <Component {...pageProps} />
                      </AuthProvider>
                    ) : (
                      <>
                        <BodyStyle />
                        <Component {...pageProps} />
                      </>
                    )}
                  </AnalyticsProvider>
                </main>
              </DemoProvider>
            </ErrorBoundary>
          </RecoilRoot>
        </ThemeProvider>
      </SnackbarProvider>
    </QueryClientProvider>
  );
}
