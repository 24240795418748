import { planOptions, plans } from "@assets/constants";

export function findPlanByPriceId(priceIdToMatch: string | undefined | null) {
  if (!priceIdToMatch) return null;
  for (const plan in planOptions) {
    // @ts-ignore
    for (const term in planOptions[plan]) {
      // @ts-ignore
      const priceId = planOptions[plan][term].priceId;
      if (priceId === priceIdToMatch) {
        return plans.find((i) => i.name === plan);
      }
    }
  }
  return null;
}
