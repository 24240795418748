import { ChevronIcon } from "@assets/icons/ChevronIcon";
import { Box, Button, SxProps, Theme } from "@mui/material";
import { useState } from "react";

export const ICON_BOX_WIDTH = 30;
export const PARENT_BAR_PADDINGY = 10;

export const CollapsibleList = ({
  ParentComponent,
  ComponentList,
  SelectedComponentList,
  ListHeaderComponent,
  FooterComponent,
  isCollapsed,
  containerStyle,
  componentListStyle,
  footerStyle,
  parentContainerStyle,
  listHeaderStyle,
  selectedComponentListStyle,
  defaultOpenState = true,
}: {
  ParentComponent?: JSX.Element;
  ComponentList: JSX.Element | JSX.Element[];
  SelectedComponentList?: JSX.Element | JSX.Element[];
  ListHeaderComponent?: JSX.Element;
  FooterComponent?: JSX.Element;
  isCollapsed: boolean;
  containerStyle?: SxProps<Theme>;
  componentListStyle?: SxProps<Theme>;
  footerStyle?: SxProps<Theme>;
  parentContainerStyle?: SxProps<Theme>;
  listHeaderStyle?: SxProps<Theme>;
  selectedComponentListStyle?: SxProps<Theme>;
  defaultOpenState?: boolean;
}) => {
  const [isOpen, setIsOpen] = useState(defaultOpenState);
  return (
    <Box
      display={"flex"}
      flexDirection={"column"}
      sx={containerStyle}
    >
      <Button
        variant="typography"
        sx={{
          display: isCollapsed ? "none" : "flex",
          flexDirection: "row",
          alignItems: "center",
          justifyContent: "flex-start",
          gap: 8,
          paddingY: PARENT_BAR_PADDINGY,
          width: "100%",
          ...parentContainerStyle,
        }}
        onClick={() => setIsOpen(!isOpen)}
      >
        <Box
          sx={{
            width: ICON_BOX_WIDTH,
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <ChevronIcon
            width={26}
            height={26}
            color="light"
            direction={isOpen ? "down" : "right"}
          />
        </Box>
        <Box
          sx={{
            width: "100%",
            display: "flex",
          }}
        >
          {ParentComponent}
        </Box>
      </Button>
      {ListHeaderComponent && (
        <Box
          sx={{
            display: "flex",
            ...listHeaderStyle,
          }}
        >
          {ListHeaderComponent}
        </Box>
      )}
      <Box
        sx={{
          display: isOpen ? "flex" : "none",
          ...(isOpen ? componentListStyle : {}),
          marginBottom: isOpen ? 10 : 0,
        }}
      >
        {ComponentList}
      </Box>
      {SelectedComponentList && (
        <Box
          sx={{
            display: isOpen ? "none" : "flex",
            listStyle: "none",
            ...selectedComponentListStyle,
          }}
        >
          {SelectedComponentList}
        </Box>
      )}
      {FooterComponent && (
        <Box sx={{ display: isOpen ? "flex" : "none", marginTop: 20, ...footerStyle }}>
          {FooterComponent}
        </Box>
      )}
    </Box>
  );
};
