import { INTERNAL_USER_EMAILS } from "@assets/constants";
import { useEffect, useState } from "react";
import { userState } from "@recoil/atoms";
import { useRouter } from "next/router";
import { useRecoilValue } from "recoil";
import log from "@services/Log";

export default function AdminProvider(props: any) {
  const router = useRouter();
  const user = useRecoilValue(userState);
  const [isInternal, setIsInternal] = useState(false);

  useEffect(() => {
    if (user && INTERNAL_USER_EMAILS.includes(user.email)) {
      setIsInternal(true);
    } else {
      // @todo escalate
      log.warn("Unauthorized access to admin page", { user });
      router.push("/");
    }
  }, [user]);

  return isInternal ? <>{props.children}</> : <></>;
}
