import { PaymentPeriod } from "@astronautchat/common-backend";
import { Box, Button, Typography } from "@mui/material";
import palette from "@styles/palette";

export const PeriodSelector = ({
  value,
  onChange,
}: {
  value: PaymentPeriod;
  onChange: (period: PaymentPeriod) => void;
}) => {
  return (
    <Box sx={{ display: "flex", gap: 10 }}>
      <Button
        sx={{
          padding: 10,
          borderRadius: 3,
          boxShadow: value === "monthly" ? "0px 0px 2px 0px #B5B5AF" : "none",
        }}
        onClick={() => onChange("monthly")}
      >
        <Typography
          variant="appCaption"
          color={value === "monthly" ? palette.brand.neutral.dark : palette.brand.neutral.darkGray}
        >
          Monthly
        </Typography>
      </Button>
      <Button
        sx={{
          padding: 10,
          borderRadius: 3,
          boxShadow: value === "annual" ? "0px 0px 2px 0px #B5B5AF" : "none",
        }}
        onClick={() => onChange("annual")}
      >
        <Typography
          variant="appCaption"
          color={value === "annual" ? palette.brand.neutral.dark : palette.brand.neutral.darkGray}
        >
          Annual (20% off)
        </Typography>
      </Button>
    </Box>
  );
};
