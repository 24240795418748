import { ComparePlans } from "@components/plans/ComparePlans";
import { useRouter } from "next/router";
import { Box } from "@mui/material";
import { customerState } from "@recoil/atoms";
import { useRecoilValue } from "recoil";
import { findPlanByPriceId } from "@lib/findPlanByPriceId";

export default function Paywall(props: { children: JSX.Element | null }) {
  const router = useRouter();

  const customer = useRecoilValue(customerState);
  const currentPlan = findPlanByPriceId(customer?.planId);
  const hasAccessToReports = true; // Everyone has access now

  return hasAccessToReports ? (
    props.children
  ) : (
    <Box>
      <ComparePlans
        isOpen={true}
        onClose={() => router.push("/chat")}
      />
    </Box>
  );
}
