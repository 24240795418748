import Head from "next/head";

export const PageHead = ({ title }: { title: string }) => {
  return (
    <Head>
      <title>{title + " · Astronaut"}</title>
      <meta
        name="description"
        content="Community insights, made simple. Easily track and share your community KPIs with your teammates and stakeholders."
        key="desc"
      />
    </Head>
  );
};
