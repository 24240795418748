import { credentialsState, demoState } from "@recoil/atoms";
import { useEffect, useState } from "react";
import { setRecoil } from "recoil-nexus";
import log from "@services/Log";
import { setChatStateValues } from "@services/Auth";
import { useRouter } from "next/router";
import { useRecoilValue } from "recoil";
import { meResponse } from "../mocks/data/me";

export function DemoProvider({ children }: any) {
  const router = useRouter();
  const isDemo = useRecoilValue(demoState);
  const isDemoPath = router.pathname.includes("/demo");
  const [mockCheckComplete, setMockCheckComplete] = useState(false);

  async function enableMocking(shouldMock: boolean) {
    if (!shouldMock) return;
    const { getWorker } = await import("../mocks/browser");
    const worker = await getWorker();
    if (worker) {
      await worker.start({
        onUnhandledRequest: "bypass",
        quiet: process.env.NODE_ENV === "production",
      });
      log.info("mock worker started for demo mode");

      setRecoil(demoState, true);

      // setting fake creds
      setRecoil(credentialsState, "demo");

      setChatStateValues(meResponse);

      // If the demo user is visiting the main /demo page, redirect them to the Reports page.
      // Otherwise, allow them to directly visit the page they are trying to access.
      if (isDemoPath) router.push("/chat/reports");
    }
    return;
  }

  useEffect(() => {
    enableMocking(isDemoPath || isDemo).finally(() => setMockCheckComplete(true));
  }, []);

  return mockCheckComplete ? children : <></>;
}
