import * as amplitude from "@amplitude/analytics-browser";
import { UserEntity } from "@astronautchat/schema";
import { config } from "@utils/config";

export const Amplitude = {
  init(email?: UserEntity["email"]) {
    amplitude.init(config.NEXT_PUBLIC_AMPLITUDE_API_KEY, email, {
      defaultTracking: {
        sessions: true,
        pageViews: true,
        formInteractions: true,
        fileDownloads: true,
      },
    });
  },
  identify(user: UserEntity) {
    amplitude.setUserId(user.email);
    const identifyEvent = new amplitude.Identify();
    identifyEvent.set("userId", user.userId)

    if (user?.email) identifyEvent.set("email", user?.email);
    if (user?.firstName) identifyEvent.set("firstName", user?.firstName);
    if (user?.lastName) identifyEvent.set("lastName", user?.lastName);

    amplitude.identify(identifyEvent);
  },
  logEvent(event: string, data?: any) {
    amplitude.track(event, data);
  },
};

export default Amplitude;
