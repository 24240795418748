import { CustomerEntity } from "@astronautchat/schema";

export function checkHasTrialExpired({
  subscriptionStatus,
  trialEnd,
}: {
  subscriptionStatus: CustomerEntity["subscriptionStatus"] | undefined;
  trialEnd: CustomerEntity["trialEnd"] | undefined;
}) {
  if (!subscriptionStatus || !trialEnd) return false;
  return subscriptionStatus === "canceled" && trialEnd ? true : false;
}
