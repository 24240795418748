import Image from "next/image";

export const WarningCircleIcon = ({
  width = 25,
  height = 25,
  color,
}: {
  width?: number;
  height?: number;
  color: "black" | "red";
}) => (
  <Image
    alt="Warning circle icon"
    src={color === "black" ? "/warning-circle-black.svg" : "/warning-circle-red.svg"}
    width={width}
    height={height}
    priority
    style={{
      objectFit: "contain",
      width,
      transition: "transform 0.25s linear",
    }}
    sizes={`(max-width: 768px) ${width}px, (max-width: 1200px) ${width}px, ${width}px`}
  />
);
