import { generateCUID } from "@astronautchat/schema";
import { adminState, credentialsState, userState } from "@recoil/atoms";
import log from "@services/Log";
import { getRecoil } from "recoil-nexus";
import { Socket, io } from "socket.io-client";

const url = process.env.NEXT_PUBLIC_SOCKET_URL;

export type NamespaceWrappedSocket = {
  on: (event: string, callback: (data: any) => void) => void;
  emit: (event: string, data: any) => void;
  off: (event: string) => void;
  reset: () => void;
  _socket: Socket;
};

export function createNamespaceSocketWrapper(socket: Socket): NamespaceWrappedSocket {
  let sessionId = generateCUID();

  function reset() {
    sessionId = generateCUID();
  }

  function on(event: string, callback: (data: any) => void) {
    socket.on(event, (data: any) => {
      if (data?.sessionId === sessionId) {
        callback(data.data);
      }
    });
  }

  function off(event: string) {
    socket.off(event);
  }

  function emit(event: string, data: any) {
    socket.emit(event, {
      sessionId,
      data,
    });
  }

  return {
    reset,
    on,
    emit,
    off,
    _socket: socket,
  };
}

export default async function createSocket() {
  try {
    const user = getRecoil(userState);
    const token = getRecoil(credentialsState);

    if (!token) {
      throw new Error("No token present");
    }

    const admin = getRecoil(adminState);

    const extraHeaders = admin
      ? {
          astrointernal: "true",
        }
      : // We dont want to send the header if it's false because that would give users the ability to sniff out our logic.
        undefined;

    const socket = io(url!, {
      autoConnect: false, // Changed to false
      reconnectionAttempts: 15,
      timeout: 30000,
      withCredentials: true,
      auth: {
        token: `Bearer ${token}`,
      },
      extraHeaders,
    });

    // Event listeners
    socket.on("connect", () => {
      log.info("Connected!");
    });

    socket.on("connect_error", (payload) => {
      try {
        throw Error("connect_error");
      } catch (error: any) {
        log.error(error, error?.message, { payload, user });
      }
    });

    socket.on("error", (payload) => {
      try {
        throw Error("Encountered error in websocket");
      } catch (error: any) {
        log.error(error, error?.message, payload);
      }
    });

    socket.on("disconnect", () => {
      log.info("Disconnected!");
    });

    return socket;
  } catch (error) {
    log.error(error, "Error creating WebSocket connection");
    throw error;
  }
}
