import { WarningCircleIcon } from "@assets/icons/WarningCircleIcon";
import { CustomContentProps, SnackbarContent, useSnackbar } from "notistack";
import { CrossIcon } from "@assets/icons/CrossIcon";
import { Box, Button, Typography } from "@mui/material";
import palette from "@styles/palette";
import React from "react";

interface ErrorSnackbarProps extends CustomContentProps {
  title?: string;
  subtitle?: string;
}

export const ErrorSnackbar = React.forwardRef<HTMLDivElement, ErrorSnackbarProps>((props, ref) => {
  const { closeSnackbar } = useSnackbar();
  const {
    // You have access to notistack props and options 👇🏼
    id,
    message,
    title,
    subtitle,
    ...other
  } = props;

  return (
    <SnackbarContent
      ref={ref}
      role="error"
      {...other}
      style={{
        background: "white",
        borderRadius: 6,
        boxShadow: "0px 0px 2px 0px #B5B5AF",
        padding: 10,
        maxWidth: 510,
      }}
    >
      <Box sx={{ display: "flex", gap: 1, alignItems: "flex-start", width: "100%" }}>
        <WarningCircleIcon
          width={24}
          height={24}
          color={"red"}
        />
        <Box sx={{ display: "flex", flexDirection: "column", width: "100%" }}>
          {title && (
            <Typography
              variant={"appBodyText"}
              color={palette.brand.neutral.darkGray}
            >
              {title}
            </Typography>
          )}
          {subtitle && (
            <Typography
              variant={"appCaption"}
              color={palette.brand.neutral.darkGray}
            >
              {subtitle}
            </Typography>
          )}
        </Box>
        <Button
          variant="typography"
          onClick={() => closeSnackbar(id)}
          sx={{
            display: "flex",
            alignItems: "center",
            height: "100%",
            minWidth: "unset",
            width: 30,
          }}
        >
          <CrossIcon
            width={18}
            height={18}
            color="black"
          />
        </Button>
      </Box>
    </SnackbarContent>
  );
});

ErrorSnackbar.displayName = "ErrorSnackbar";
