import Image from "next/image";

export const CheckboxCircleIcon = ({
  width = 25,
  height = 25,
}: {
  width?: number;
  height?: number;
}) => (
  <Image
    alt="Checkbox circle icon"
    src={"/checkbox-circle.svg"}
    width={width}
    height={height}
    priority
    style={{
      objectFit: "contain",
      width,
    }}
    sizes={`(max-width: 768px) ${width}px, (max-width: 1200px) ${width}px, ${width}px`}
  />
);
