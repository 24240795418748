import { Box, Button, CircularProgress, Typography } from "@mui/material";
import { ShieldIcon } from "@assets/icons/ShieldIcon";
import { PeriodSelector } from "./PeriodSelector";
import { BoltIcon } from "@assets/icons/BoltIcon";
import { SunIcon } from "@assets/icons/SunIcon";
import { PlanListItem } from "./UpgradeDialog";
import { Dialog } from "@components/Dialog";
import { plans } from "@assets/constants";
import Payment from "@services/Payment";
import palette from "@styles/palette";
import { useEffect, useState } from "react";
import log from "@services/Log";
import { useRecoilValue } from "recoil";
import { customerState } from "@recoil/atoms";
import { findPlanByPriceId } from "@lib/findPlanByPriceId";
import Customer from "@services/Customer";
import { PaymentPeriod, Plan, PlanOptionName } from "@astronautchat/common-backend";

const PlanBlock = ({
  title,
  price,
  unit,
  features,
  isCurrent,
  onUpgrade,
}: Pick<Plan, "title" | "features"> & {
  price: number | string;
  unit: string;
  isCurrent: boolean;
  onUpgrade: () => void;
}) => {
  const [isProcessing, setIsProcessing] = useState<boolean>(false);

  const handleOnUpgrade = async () => {
    setIsProcessing(true);
    await onUpgrade();
    setIsProcessing(false);
  };

  return (
    <Box
      sx={{
        borderRadius: 12,
        background: title === "Pro" ? palette.brand.neutral.dark : "white",
        boxShadow: "0px 0px 2px 0px #B5B5AF",
        padding: "20px 10px",
        minWidth: 300,
      }}
    >
      <Box sx={{ display: "flex", flexDirection: "column", gap: 10 }}>
        {title === "Starter" ? (
          <SunIcon
            width={24}
            height={24}
          />
        ) : title === "Pro" ? (
          <BoltIcon
            width={24}
            height={24}
          />
        ) : (
          <ShieldIcon
            width={24}
            height={24}
          />
        )}
        <Typography
          variant="appBodyText"
          color={title === "Pro" ? "white !important" : palette.brand.neutral.darkGray}
        >
          {title}
        </Typography>
      </Box>
      <Box
        sx={{
          height: 2,
          background:
            title === "Pro" ? palette.brand.neutral.darkGray : palette.brand.neutral.lightGray,
          width: "100%",
          marginY: 20,
        }}
      />
      <Typography
        variant="appSubtitle"
        sx={{ display: "flex", flexDirection: "row", alignItems: "center", gap: 5 }}
        color={title === "Pro" ? "white !important" : palette.brand.neutral.darkGray}
      >
        {price === "Custom" ? "Custom" : "$" + price.toString()}
        {price !== "Custom" && (
          <Typography
            variant="appCaption"
            color={palette.brand.neutral.darkGray}
          >
            {unit}
          </Typography>
        )}
      </Typography>
      <Box sx={{ display: "flex", flexDirection: "column", marginTop: 20, gap: 10 }}>
        {features.map((i) => (
          <PlanListItem
            key={i}
            title={i}
            theme={title === "Pro" ? "light" : "dark"}
          />
        ))}
      </Box>
      <Button
        variant={title === "Pro" ? "primary" : "secondary"}
        className="small"
        sx={{ width: "100%", marginTop: 20 }}
        disabled={isCurrent || isProcessing}
        onClick={handleOnUpgrade}
      >
        {isProcessing ? (
          <CircularProgress
            size={16}
            style={{ color: "white" }}
          />
        ) : isCurrent ? (
          "Current plan"
        ) : title === "Pro" ? (
          "Go Pro"
        ) : title === "Enterprise" ? (
          "Contact us"
        ) : (
          "Upgrade"
        )}
      </Button>
    </Box>
  );
};

export const ComparePlans = ({
  isOpen,
  onClose,
  defaultPeriod,
}: {
  isOpen: boolean;
  onClose: () => void;
  defaultPeriod?: PaymentPeriod;
}) => {
  const customer = useRecoilValue(customerState);
  const currentPlan = findPlanByPriceId(customer?.planId);

  const [period, setPeriod] = useState<PaymentPeriod>(defaultPeriod || "annual");
  const starter = plans.find((i) => i.title === "Starter")!;
  const pro = plans.find((i) => i.title === "Pro")!;
  const enterprise = plans.find((i) => i.title === "Enterprise")!;

  const onUpgrade = async ({ planName }: { planName: PlanOptionName }) => {
    try {
      if (planName === "enterprise") {
        window.location.href = "mailto:ayoola@astronaut.chat";
      } else {
        if (customer?.subscriptionStatus === "trialing" || !customer?.subscriptionId) {
          await Payment.checkout({
            option: planName,
            period,
            customerId: customer!.customerId,
            stripeCustomerId: customer?.stripeCustomerId,
          });
        } else {
          await Customer.getCheckoutPortalUrl({
            customerId: customer!.customerId,
            newPeriod: period,
            newPlanName: planName,
          });
        }
      }
    } catch (error: any) {
      log.error(error, error?.message);
    }
  };

  useEffect(() => {
    setPeriod(defaultPeriod || "annual");
  }, [defaultPeriod]);

  return (
    <Dialog
      open={isOpen}
      onClose={onClose}
      title={`Choose the right plan for you`}
      sx={{ overflow: "hidden" }}
    >
      <Box
        sx={{
          justifyContent: "space-between",
          alignItems: "flex-end",
          display: "center",
          width: "100%",
          marginBottom: 20,
        }}
      >
        <Typography
          variant="appCaption"
          color={palette.brand.neutral.darkGray}
        >
          Risk free, cancel at anytime
        </Typography>
        <PeriodSelector
          value={period}
          onChange={setPeriod}
        />
      </Box>
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          overflowX: "scroll",
          width: "100%",
          padding: 2,
          gap: 20,
          alignItems: "flex-start",
        }}
      >
        <PlanBlock
          title={starter.title}
          onUpgrade={() => onUpgrade({ planName: starter.name })}
          price={period === "annual" ? starter.annualPrice : starter.monthlyPrice}
          unit={starter.unit}
          features={starter.features}
          isCurrent={
            currentPlan?.name === starter.name && customer?.subscriptionStatus !== "trialing"
          }
        />
        <PlanBlock
          title={pro.title}
          onUpgrade={() => onUpgrade({ planName: pro.name })}
          price={period === "annual" ? pro.annualPrice : pro.monthlyPrice}
          unit={pro.unit}
          features={pro.features}
          isCurrent={currentPlan?.name === pro.name}
        />
        <PlanBlock
          title={enterprise.title}
          onUpgrade={() => onUpgrade({ planName: enterprise.name })}
          price={period === "annual" ? enterprise.annualPrice : enterprise.monthlyPrice}
          unit={enterprise.unit}
          features={enterprise.features}
          isCurrent={currentPlan?.name === enterprise.name}
        />
      </Box>
    </Dialog>
  );
};
