import { Column } from "@components/common/Column";
import { Typography } from "@mui/material";
import { PageHead } from "./Page/PageHead";
import { Onboarding } from "./Onboarding";
import log from "@services/Log";
import React from "react";

class ErrorBoundary extends React.Component {
  constructor(props: any) {
    super(props);

    // Define a state variable to track whether is an error or not
    this.state = { hasError: false };
  }
  static getDerivedStateFromError(error: any) {
    // Update state so the next render will show the fallback UI

    return { hasError: true };
  }
  componentDidCatch(error: any, errorInfo: any) {
    log.error(error, error?.message, errorInfo);
  }
  render() {
    // Check if the error is thrown
    // @ts-ignore
    if (this.state.hasError) {
      // Fallback UI
      return (
        <Onboarding shouldCenterAlignBody>
          <PageHead title={"Check your inbox"} />
          <Column gap={10}>
            <Typography
              variant="appH2"
              textAlign={"center"}
            >
              Sorry, there's been an error!
            </Typography>
            <Typography
              variant="appBodyText"
              textAlign={"center"}
            >
              We've notified our team.
            </Typography>
            <Typography
              variant="appBodyText"
              textAlign={"center"}
            >
              If issues continue, please email Chris at{" "}
              <a
                href="mailto:chris@astronaut.chat"
                style={{ color: "blue" }}
              >
                <b>chris@astronaut.chat</b>
              </a>
              .
            </Typography>
          </Column>
        </Onboarding>
      );
    }

    // Return children components in case of no error
    // @ts-ignore
    return this.props.children;
  }
}

export default ErrorBoundary;
