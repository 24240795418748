import publicApi from "@api/publicApi";
import { decideStartPage } from "@lib/decideStartPage";
import { Box, CircularProgress, Typography } from "@mui/material";
import {
  adminState,
  credentialsState,
  demoState,
  moderationRolesState,
  sessState,
  userState,
} from "@recoil/atoms";
import Admin from "@services/Admin";
import { setChatStateValues } from "@services/Auth";
import user from "@services/User";
import palette from "@styles/palette";
import { Onboarding } from "@templates/Onboarding";
import { AxiosResponse } from "axios";
import { useRouter } from "next/router";
import { useEffect, useState } from "react";
import { useRecoilValue } from "recoil";
import { getRecoil, setRecoil } from "recoil-nexus";

type AuthProps = {
  type: "chat" | "intro";
  children: JSX.Element | JSX.Element[];
};

export default function AuthProvider(props: AuthProps) {
  const router = useRouter();
  const admin = useRecoilValue(adminState);
  const userRecoil = useRecoilValue(userState);
  const token = useRecoilValue(credentialsState);

  const [authenticated, setAuthenticated] = useState<boolean>(false);
  const isInDemo = getRecoil(demoState);

  useEffect(() => {
    if (userRecoil && token) {
      Admin.notifyAstronaut({
        type: "view-page",
        data: {
          email: userRecoil?.email,
          pageName: router.pathname,
        },
      });
    }
  }, [userRecoil, router.pathname]);

  useEffect(() => {
    if (isInDemo) {
      setAuthenticated(true);
      return;
    }
    // try and get access token
    publicApi
      .get("/auth/credentials", {
        headers: {
          Authorization: `Bearer ${getRecoil(sessState)}`,
        },
      })
      .then((res: AxiosResponse<any, any>) => {
        setRecoil(credentialsState, res.data);
        return user.getAuthorizedUser();
      })
      .then((user) => {
        setRecoil(userState, user);
        setChatStateValues(user);

        const path = decideStartPage(user, true);
        if (path) router.push(path);
      })
      .catch((e) => {
        // go to signin page, credential fetching failed
        return router.push("/signin");
      })
      .finally(() => {
        setAuthenticated(true);
      });
  }, []);

  useEffect(() => {
    if (isInDemo) {
      setAuthenticated(true);
      return;
    }
    const refreshCredentials = setInterval(() => {
      publicApi
        .get("/auth/credentials", {
          headers: {
            Authorization: `Bearer ${getRecoil(sessState)}`,
          },
        })
        .then((res: AxiosResponse<any, any>) => {
          setRecoil(credentialsState, res.data);
        })
        .catch((e) => {
          if (e.response.status === 401) {
            return router.push("/signin");
          }
        });
    }, 1000 * 60 * 5);

    return () => {
      clearInterval(refreshCredentials);
    };
  }, []);

  return authenticated ? (
    <>
      {props.children}
      {admin && (
        <Box
          sx={{
            height: 20,
            borderRadius: 6,
            position: "absolute",
            top: 10,
            right: 10,
            background: "black",
            zIndex: 10000,
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            paddingX: 10,
            paddingY: 10,
            cursor: "pointer",
          }}
          onClick={() => {
            setRecoil(userState, undefined);
            setRecoil(adminState, undefined);
            setRecoil(moderationRolesState, []);
            router.reload();
          }}
        >
          <Typography
            variant="appCaption"
            color={palette.brand.neutral.light}
            fontWeight={600}
          >
            Log out
          </Typography>
        </Box>
      )}
    </>
  ) : (
    <Onboarding shouldCenterAlignBody>
      <div
        style={{
          width: "100%",
          height: "100%",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <CircularProgress
          size={22}
          style={{ color: "#222" }}
        />
      </div>
    </Onboarding>
  );
}
