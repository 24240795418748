// @ts-nocheck
import { useEffect, ReactNode } from "react";
import { adminState, userState } from "@recoil/atoms";
import { useRecoilValue } from "recoil";
import { INTERNAL_USER_EMAILS } from "@assets/constants";
import amplitude from "@services/Amplitude";

const AnalyticsProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
  const user = useRecoilValue(userState);
  const admin = useRecoilValue(adminState);

  useEffect(() => {
    if (process.env.NODE_ENV !== "production") return; // dont track in development
    if (admin) return; // dont track admin activity
    if (INTERNAL_USER_EMAILS.includes(user?.email)) return; // dont track internal activity

    (function (c, l, a, r, i, t, y) {
      c[a] =
        c[a] ||
        function () {
          (c[a].q = c[a].q || []).push(arguments);
        };
      t = l.createElement(r);
      t.async = 1;
      t.src = "https://www.clarity.ms/tag/" + i;
      y = l.getElementsByTagName(r)[0];
      y.parentNode.insertBefore(t, y);
    })(window, document, "clarity", "script", "js2vj2mwfm");

    if (user) {
      window.clarity("identify", user?.email);
      window.clarity("set", "userId", user?.email);
    }

    if (user?.firstName) {
      const name = `${user?.firstName} ${user?.lastName}`;
      window.clarity("set", "name", name);
    }

    if (user) {
      amplitude.identify(user);
    }
  }, [user]);

  useEffect(() => {
    if (admin) return;
    amplitude.init(user?.email);
  }, [admin]);

  return <>{children}</>;
};

export default AnalyticsProvider;
