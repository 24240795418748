import Image from "next/image";

export const MemberIcon = ({
  width = 24,
  height = 24,
  color,
}: {
  width?: number;
  height?: number;
  color?: "gray" | "white";
}) => (
  <Image
    alt="member icon"
    src={color === "gray" ? "/member-gray.svg" : "/member.svg"}
    width={width}
    height={height}
    priority
    style={{
      objectFit: "contain",
      width,
    }}
    sizes={`(max-width: 768px) ${width}px, (max-width: 1200px) ${width}px, ${width}px`}
  />
);
