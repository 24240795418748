import Image from "next/image";

export const ShieldIcon = ({ width = 24, height = 24 }: { width?: number; height?: number }) => (
  <Image
    alt="Shield icon"
    src={"/shield-cross.svg"}
    width={width}
    height={height}
    priority
    style={{
      objectFit: "contain",
      width,
    }}
    sizes={`(max-width: 768px) ${width}px, (max-width: 1200px) ${width}px, ${width}px`}
  />
);
