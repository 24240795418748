import { ICON_BOX_WIDTH, PARENT_BAR_PADDINGY } from "@components/CollapsibleList";
import { Row } from "@components/common/Row";
import { Button, Typography } from "@mui/material";
import palette from "@styles/palette";
import { LockIcon } from "@assets/icons/LockIcon";

export const NavItem = ({
  isActive,
  isCollapsed,
  onClick,
  isLocked,
  title,
  IconComponent,
  isIdented,
}: {
  isActive: boolean;
  isCollapsed: boolean;
  onClick: () => void;
  isLocked: boolean;
  title?: string;
  IconComponent: JSX.Element;
  isIdented?: boolean;
}) => {
  return (
    <Button
      variant="typography"
      sx={{
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        justifyContent: isCollapsed ? "center" : "flex-start",
        gap: 11,
        paddingX: isCollapsed ? 0 : isIdented ? 35 : 15,
        width: "100%",
        paddingY: PARENT_BAR_PADDINGY,
        background: isActive ? "#333" : "transparent",
        opacity: isLocked ? 0.5 : 1,
      }}
      onClick={onClick}
    >
      <Row
        width={ICON_BOX_WIDTH}
        alignItems={"center"}
        justifyContent={"center"}
      >
        {IconComponent}
      </Row>
      {isCollapsed ? null : !!title ? (
        <Row
          alignItems={"center"}
          justifyContent={"space-between"}
          width="90%" // BUG: using 90% because 100% expands the width of the component
        >
          <Typography
            variant="appNavText"
            color={palette.brand.neutral.light}
          >
            {title}
          </Typography>
          {isLocked ? (
            <LockIcon
              width={24}
              height={24}
            />
          ) : null}
        </Row>
      ) : (
        <></>
      )}
    </Button>
  );
};
