import { plans } from "@assets/constants";
import { StarIcon } from "@assets/icons/StarIcon";
import { Box, Button, CircularProgress, Typography } from "@mui/material";
import { PeriodSelector } from "./PeriodSelector";
import { ComparePlans } from "./ComparePlans";
import { Dialog } from "@components/Dialog";
import Payment from "@services/Payment";
import palette from "@styles/palette";
import { useState } from "react";
import log from "@services/Log";
import { customerState } from "@recoil/atoms";
import { useRecoilValue } from "recoil";
import Customer from "@services/Customer";
import { PaymentPeriod, PlanOptionName, PlanTitle } from "@astronautchat/common-backend";

export const PlanListItem = ({
  title,
  theme = "light",
}: {
  title: string;
  theme: "light" | "dark";
}) => {
  return (
    <Box sx={{ display: "flex", flexDirection: "row", alignItems: "center", gap: 10 }}>
      <StarIcon
        width={24}
        height={24}
        color={theme === "light" ? "pink" : "black"}
      />
      <Typography
        variant="webCaptionText"
        dangerouslySetInnerHTML={{
          __html: title,
        }}
        color={theme === "light" ? "white !important" : palette.brand.neutral.dark}
      />
    </Box>
  );
};

export const UpgradeDialog = ({
  isOpen,
  onClose,
  planTitle,
  planName,
  defaultPeriod = "annual",
}: {
  planTitle: PlanTitle;
  planName: PlanOptionName;
  isOpen: boolean;
  onClose: () => void;
  defaultPeriod?: PaymentPeriod;
}) => {
  const customer = useRecoilValue(customerState);
  const plan = plans.find((i) => i.title === planTitle);
  const [period, setPeriod] = useState<PaymentPeriod>(defaultPeriod);
  const price = period === "annual" ? plan?.annualPrice : plan?.monthlyPrice;
  const [openComparePlans, setOpenComparePlans] = useState<boolean>(false);
  const [isProcessing, setIsProcessing] = useState<boolean>(false);

  const onUpgrade = async () => {
    setIsProcessing(true);
    try {
      if (customer?.subscriptionStatus === "trialing" || !customer?.subscriptionId) {
        await Payment.checkout({
          option: planName,
          period,
          customerId: customer!.customerId,
          stripeCustomerId: customer?.stripeCustomerId,
        });
      } else {
        await Customer.getCheckoutPortalUrl({
          customerId: customer!.customerId,
          newPeriod: period,
          newPlanName: planName,
        });
      }
    } catch (error: any) {
      log.error(error, error?.message);
    }
    setIsProcessing(false);
  };

  return (
    <Dialog
      open={isOpen}
      onClose={onClose}
      title={`🎉 Upgrade to Astronaut ${plan?.title}`}
      width={510}
    >
      <Box sx={{ display: "flex", justifyContent: "flex-end", width: "100%" }}>
        <PeriodSelector
          value={period}
          onChange={setPeriod}
        />
      </Box>
      <Box sx={{ marginY: 40 }}>
        <Box sx={{ display: "flex", justifyContent: "flex-start", width: "100%" }}>
          <Typography
            variant="appSubtitle"
            sx={{ display: "flex", flexDirection: "row", alignItems: "center", gap: 5 }}
            color={palette.brand.neutral.dark}
          >
            {price !== "Custom" && "$"}
            {price}
            {price !== "Custom" && (
              <Typography
                variant="appCaption"
                color={palette.brand.neutral.darkGray}
              >
                {plan?.unit}
              </Typography>
            )}
          </Typography>
        </Box>
        <Box sx={{ display: "flex", flexDirection: "column", marginTop: 20, gap: 10 }}>
          {plan?.features.map((i) => (
            <PlanListItem
              key={i}
              title={i}
              theme="dark"
            />
          ))}
        </Box>
      </Box>
      <Box
        sx={{
          width: "100%",
          display: "flex",
          justifyContent: "center",
          marginBottom: 20,
        }}
      >
        <Typography
          variant="tertiary"
          color={palette.brand.neutral.darkGray}
          sx={{
            textDecoration: "underline",
            textUnderlineOffset: 3,
            cursor: "pointer",
          }}
          onClick={() => setOpenComparePlans(true)}
        >
          See all plans
        </Typography>
      </Box>
      <Button
        variant="primary"
        sx={{ width: "100%" }}
        onClick={onUpgrade}
        disabled={isProcessing}
      >
        {isProcessing ? (
          <CircularProgress
            size={16}
            style={{ color: "white" }}
          />
        ) : (
          "Upgrade now"
        )}
      </Button>
      <ComparePlans
        isOpen={openComparePlans}
        defaultPeriod={period}
        onClose={() => setOpenComparePlans(false)}
      />
    </Dialog>
  );
};
