import { styled } from "@mui/material/styles";
import { collapseNavContainerState } from "@recoil/utilityAtoms";
import { NAV_CHAT_WIDTH } from "@styles/constants";
import palette from "@styles/palette";
import { useRecoilValue } from "recoil";

type WrapperProps = {
  isCollapsed: boolean;
  color: string;
  collapsedWidth: number;
};

const Wrapper = styled("div")(
  ({ isCollapsed, color, collapsedWidth }: WrapperProps) => `
  display: flex;
  flex-direction: column;

  height: auto;
  width: ${isCollapsed ? `${collapsedWidth}px` : `${NAV_CHAT_WIDTH}px`};
  min-width: ${isCollapsed ? `${collapsedWidth}px` : `${NAV_CHAT_WIDTH}px`};
  transition: width 0.25s ease-out, min-width 0.25s ease-out;

  background: ${color};
  
  @media (max-width: 768px) {
    border-top-width: 0px;
    border-right-width: 0px;
    border-bottom-width: 1px;
    border-left-width: 0px;
    
    width: 100vw;

    height: auto;
    max-height: 100px;
    transform: unset;
    transition: transform 0.5s ease-out, max-height 0.5s ease-out;
  }
`
);

/**
 * @features collapsable, custom color at the top-level, adjustable mobile view
 * Pass the collapsable callback into a child so that it can be triggered from there
 */
export const NavContainer = ({
  children,
  color = palette.black.i2,
  collapsedWidth,
}: {
  children: JSX.Element | JSX.Element[];
  color: string;
  collapsedWidth: number;
}) => {
  const isCollapsed = useRecoilValue(collapseNavContainerState);
  return (
    <Wrapper
      id="navbar"
      color={color}
      isCollapsed={isCollapsed}
      collapsedWidth={collapsedWidth}
    >
      {children}
    </Wrapper>
  );
};
