import { CustomContentProps, SnackbarContent, useSnackbar } from "notistack";
import { CheckboxCircleIcon } from "@assets/icons/CheckboxCircleIcon";
import { Box, Button, Typography } from "@mui/material";
import { CrossIcon } from "@assets/icons/CrossIcon";
import palette from "@styles/palette";
import React from "react";

interface SuccessSnackbarProps extends CustomContentProps {
  title?: string;
  subtitle?: string;
}

export const SuccessSnackbar = React.forwardRef<HTMLDivElement, SuccessSnackbarProps>(
  (props, ref) => {
    const { closeSnackbar } = useSnackbar();
    const {
      // You have access to notistack props and options 👇🏼
      id,
      message,
      title,
      subtitle,
      ...other
    } = props;

    return (
      <SnackbarContent
        ref={ref}
        role="info"
        {...other}
        style={{
          background: "white",
          borderRadius: 6,
          boxShadow: "0px 0px 2px 0px #B5B5AF",
          padding: 10,
          maxWidth: 510,
        }}
      >
        <Box
          sx={{
            display: "flex",
            gap: 1,
            alignItems: title && subtitle ? "flex-start" : "center",
            width: "100%",
          }}
        >
          <CheckboxCircleIcon
            width={18}
            height={18}
          />
          <Box sx={{ display: "flex", flexDirection: "column", width: "100%" }}>
            {title && (
              <Typography
                variant={"appBodyText"}
                color={palette.brand.neutral.darkGray}
              >
                {title}
              </Typography>
            )}
            {subtitle && (
              <Typography
                variant={"appCaption"}
                color={palette.brand.neutral.darkGray}
              >
                {subtitle}
              </Typography>
            )}
          </Box>
          <Button
            variant="typography"
            onClick={() => closeSnackbar(id)}
            sx={{
              display: "flex",
              alignItems: "center",
              height: "100%",
              minWidth: "unset",
              width: 30,
            }}
          >
            <CrossIcon
              width={18}
              height={18}
              color="black"
            />
          </Button>
        </Box>
      </SnackbarContent>
    );
  }
);

SuccessSnackbar.displayName = "SuccessSnackbar";
