import { Components } from "@mui/material/styles";
import palette from "./palette";
import typography from "./typography";

/**
 * For dashed class names, you simply add the class as a top-level property. For example, if you want to edit the "MuiOutlinedInput-root" class, you add:
 *
 * MuiOutlinedInput: {
 styleOverrides: {
 root: {}
 }
 }
 * The same is true for -inputRoot, -inputFocused, etc.
 */

type ComponentType = {
  [key in keyof Components]: any;
};

export const components = {
  border: {
    primary: {
      border: "1px solid",
      borderColor: palette.brand.neutral.gray,
      borderRadius: 6,
    },
  },
};

export const muiComponents: ComponentType = {
  MuiOutlinedInput: {
    styleOverrides: {
      // This styles the inactive state of TextField
      notchedOutline: {
        borderColor: "black",
        borderWidth: 2,
      },
    },
  },
  MuiTextField: {
    styleOverrides: {
      root: {
        fontFamily: "var(--font-inter)",
        fontSize: 16,

        "& .MuiOutlinedInput-root": {
          borderRadius: 18,
          padding: 0,
          paddingRight: 15,
          // This styles the active state of TextField
          "&.Mui-focused fieldset": {
            borderColor: palette.brand.grey.i4,
          },
          "& .MuiOutlinedInput-input": {
            padding: "16px 20px",
            "&::placeholder": {
              color: palette.brand.grey.i4,
              opacity: 1,
            },
          },
          "& .MuiOutlinedInput-notchedOutline": {
            border: "2px solid",
            borderRadius: 18,
            borderColor: palette.black.i1,
          },
        },
        "&.tag": {
          "& .MuiOutlinedInput-root": {
            color: palette.brand.neutral.dark,
            background: "#fff",

            "& .MuiOutlinedInput-input": {
              color: "black",
              "&::placeholder": {
                color: palette.brand.grey.i4,
                opacity: 1,
              },
            },
            "& .MuiOutlinedInput-notchedOutline": {
              ...components.border.primary,
            },
          },
        },
        // WHITE VARIATION
        "&.white": {
          "& .MuiOutlinedInput-root": {
            color: palette.white.i10, // color of text that has been entered
            "&.Mui-focused fieldset": {
              borderColor: palette.white.i10,
              color: palette.white.i10,
            },
            "& .MuiOutlinedInput-input": {
              "&::placeholder": {
                color: palette.brand.grey.i3,
              },
            },
            "& .MuiOutlinedInput-notchedOutline": {
              borderColor: palette.brand.grey.i3,
            },
          },
        },
        // BLACK VARIATION
        "&.black": {
          "& .MuiOutlinedInput-root": {
            color: palette.black.i10, // color of text that has been entered
            "&.Mui-focused fieldset": {
              borderColor: palette.black.i1,
              color: palette.black.i10,
            },
            "& .MuiOutlinedInput-input": {
              "&::placeholder": {
                color: palette.brand.grey.i4,
              },
            },
            "& .MuiOutlinedInput-notchedOutline": {
              borderColor: palette.black.i1,
            },
          },
        },
        "&.empty": {
          "& .MuiOutlinedInput-root": {
            ...typography.appBodyText,
            "& .MuiOutlinedInput-input": {
              textAlign: "left",
              padding: 0,
              "&::placeholder": {
                color: palette.brand.grey.i4,
              },
            },
          },
          "& .MuiOutlinedInput-notchedOutline": {
            border: 0,
          },
        },
        "&.comment": {
          "& .MuiOutlinedInput-root": {
            ...typography.appCaption,
            "& .MuiOutlinedInput-input": {
              textAlign: "left",
              padding: 0,
              "&::placeholder": {
                color: palette.brand.grey.i4,
              },
              "&.Mui-disabled": { "-webkit-text-fill-color": "unset" },
            },
          },
          "& .MuiOutlinedInput-notchedOutline": {
            border: 0,
          },
        },
        "&.search": {
          backgroundColor: palette.brand.neutral.lightGray,
          padding: 8,
          borderRadius: 8,
          border: "2px solid " + palette.brand.neutral.gray,
          "& .MuiOutlinedInput-root": {
            ...typography.appBodyText,
            "& .MuiOutlinedInput-input": {
              textAlign: "left",
              padding: 0,
              "&::placeholder": {
                color: palette.brand.neutral.gray,
              },
            },
          },
          "& .MuiOutlinedInput-notchedOutline": {
            border: 0,
          },
        },
      },
    },
  },
  // This styles the label of TextField
  MuiInputLabel: {
    styleOverrides: {
      root: {
        color: "rgba(0,0,0,0.75)",
        fontWeight: 500,
        "&.Mui-focused": {
          color: "black",
        },
      },
    },
  },
  MuiButton: {
    styleOverrides: {
      root: {
        minWidth: "unset",
        backgroundColor: "#806DFF",
        "&:hover": {
          filter: "brightness(0.85)",
        },
        "&:active": {
          transform: "scale(0.95)",
          transition: "all .2s ease-in-out",
        },
        "&.google": {
          backgroundColor: palette.external.google.secondary,
          "&:hover": {
            background: palette.external.google.secondary,
            opacity: 0.85,
          },
        },
      },
      typography: {
        background: "transparent",
        textTransform: "unset",
        minWidth: "unset",
        width: "fit-content",
        padding: "unset",
        "&:hover": {
          background: "transparent",
          filter: "unset",
        },
        "&.Mui-disabled": {},
      },
      secondary: {
        ...typography.appButtonText,
        padding: "21px 20px",
        height: 66,
        textTransform: "unset",
        background: "transparent",
        border: "2px solid",
        color: palette.brand.neutral.darkGray,
        borderColor: palette.brand.neutral.darkGray,
        borderRadius: 6,
        filter: "unset",
        "&:hover": {
          background: palette.brand.neutral.gray,
          borderColor: palette.brand.neutral.gray,
          color: palette.brand.neutral.light,
        },
        "&:active": {
          background: palette.brand.neutral.darkGray,
          borderColor: palette.brand.neutral.darkGray,
          color: palette.brand.neutral.light,
        },
        "&.alt": {
          background: palette.brand.neutral.dark,
          borderColor: palette.brand.neutral.dark,
          color: palette.brand.neutral.light,
        },
        "&.small": {
          minWidth: "unset",
          height: "unset",
          padding: "10px 20px !important",
          background: "transparent",
          color: palette.brand.neutral.darkGray,
          borderColor: palette.brand.neutral.darkGray,
          "&:hover": {
            background: palette.brand.neutral.gray,
            borderColor: palette.brand.neutral.gray,
            color: palette.brand.neutral.light,
          },
          "&:active": {
            background: palette.brand.neutral.darkGray,
            borderColor: palette.brand.neutral.darkGray,
            color: palette.brand.neutral.light,
          },
          "&.alt": {
            background: palette.brand.neutral.dark,
            color: palette.brand.neutral.light,
            borderColor: palette.brand.neutral.dark,
          },
        },
      },
      primary: {
        ...typography.appButtonText,
        padding: "21px 20px",
        minWidth: 159,
        height: 66,
        textTransform: "unset",
        background: palette.brand.neutral.dark,
        border: "2px solid",
        color: palette.brand.neutral.light,
        borderColor: palette.brand.neutral.dark,
        borderRadius: 6,
        "&:hover": {
          background: palette.brand.neutral.gray,
          borderColor: palette.brand.neutral.gray,
          color: palette.brand.neutral.light,
        },
        "&:active": {
          background: palette.brand.neutral.darkGray,
          borderColor: palette.brand.neutral.darkGray,
          color: palette.brand.neutral.light,
        },
        "&.alt": {
          background: palette.brand.neutral.gray,
          borderColor: palette.brand.neutral.gray,
          color: palette.brand.neutral.light,
        },
        "&.black": {
          height: 40,
          minWidth: "unset",
          fontSize: 16,
          padding: 10,
        },
        "&.tight": {
          height: 40,
          minWidth: "unset",
          fontSize: 16,
        },
        "&.small": {
          minWidth: "unset",
          height: "unset",
          padding: "10px 20px !important",
          background: palette.brand.pink2.default,
          color: palette.brand.neutral.light,
          borderColor: palette.brand.pink2.default,
          "&:hover": {
            background: palette.brand.pink2.hover,
            borderColor: palette.brand.pink2.hover,
            color: palette.brand.neutral.light,
          },
          "&:active": {
            background: palette.brand.pink2.shadow,
            borderColor: palette.brand.pink2.shadow,
            color: palette.brand.neutral.light,
          },
          "&.alt": {
            background: palette.brand.pink2.disabled,
            borderColor: palette.brand.pink2.disabled,
            color: palette.brand.neutral.light,
          },
        },
        "&.error": {
          backgroundColor: palette.brand.error.default,
          borderColor: palette.brand.error.default,
          "&:hover": {
            background: palette.brand.error.default,
            borderColor: palette.brand.error.default,
            color: palette.brand.neutral.light,
          },
          "&:active": {
            background: palette.brand.error.default,
            borderColor: palette.brand.error.default,
            color: palette.brand.neutral.light,
          },
          "&.alt": {
            background: palette.brand.error.default,
            color: palette.brand.neutral.light,
            borderColor: palette.brand.error.default,
          },
        },
      },
      outlined: {
        backgroundColor: "#806DFF",
        color: "white",
        borderWidth: 0,
        outline: 0,
        fontWeight: 500,
        "&:hover": {
          opacity: 1,
          borderWidth: 0,
          backgroundColor: "#FF38AA",
        },
        "&.Mui-disabled": {
          opacity: 0.5,
          color: "white",
          borderWidth: 0,
          outline: 0,
          fontWeight: 500,
        },
      },
      outlinedHollow: {
        background: "transparent",
        ...components.border.primary,
        ...typography.appCaption,
        color: palette.brand.neutral.darkGray,
        fontWeight: 500,
        textTransform: "unset",
        "&:hover": {
          background: palette.brand.neutral.gray,
          ...components.border.primary,
          color: "white",
        },
      },
      full: {
        background: "black",
        textTransform: "unset",
        fontFamily: "var(--font-k2d)",
        width: "100%",
        maxWidth: 350,
        border: 0,
        borderRadius: 70,
        fontSize: "20px",
        fontWeight: 500,
        color: "#fff",
        padding: "20px 0px",
        "&:hover": {
          background: "black",
        },
        "&.Mui-disabled": {},
        "&.yellow": {
          color: palette.black.i2,
          fontWeight: 600,
          background: palette.brand.yellow.i3,
          border: "2px solid",
          borderColor: palette.black.i2,
        },
      },
      login: {
        background: palette.brand.yellow.original,
        textTransform: "unset",
        padding: "20px 0px",
        borderRadius: 70,
        lineHeight: 1,
        width: "100%",
        maxWidth: 350,
        maxHeight: 60,
        border: 0,
        ...typography.appH3,
        color: palette.black.i2,
        "&:hover": {
          background: palette.brand.yellow.original,
          opacity: 0.85,
        },
        "&.Mui-disabled": {},
      },
      hollow: {
        background: "transparent",
        textTransform: "unset",
        width: "100%",
        maxWidth: 350,
        border: "2px solid",
        borderColor: "black",
        borderRadius: "70px",
        fontSize: 20,
        fontWeight: 700,
        color: "#000",
        padding: "18px 0px",
        "&:hover": {
          backgroundColor: "rgba(0,0,0,0.1)",
        },
        "&.Mui-disabled": {},
      },
      severe: {
        ...typography.appH3,
        background: "transparent",
        textTransform: "unset",
        border: "0px",
        color: "#FF4E4E",
        padding: "0px",
        "&:hover": {
          backgroundColor: "transparent",
        },
        "&.Mui-disabled": {},
      },
      standard: {
        ...typography.appH3,
        textTransform: "unset",
        padding: "20px",
        width: "fit-content",
        borderRadius: 6,
        "&.transparent": {
          border: "2px solid",
          borderColor: palette.black.i2,
          background: "transparent",
          color: palette.black.i2,
        },
        "&.white": {
          border: "2px solid",
          borderColor: palette.black.i2,
          background: "white",
          color: palette.black.i2,
        },
        "&.black": {
          background: palette.black.i2,
          border: "2px solid",
          borderColor: palette.black.i2,
          color: "#fff",
          "&:hover": {
            background: palette.black.i3,
          },
        },
        "&.Mui-disabled": {},
      },
      fit: {
        ...typography.appH3,
        textTransform: "unset",
        width: "100%",
        background: "transparent",
        borderRadius: 21,
        border: "2px solid",
        borderColor: palette.black.i2,
        "&.active": {
          background: palette.brand.yellow.i3,
          "&:hover": {
            // background: palette.brand.yellow.i2,
          },
        },
        "&.inactive": {},
        "&.red": {
          background: palette.brand.red.original,
          border: "2px solid",
          borderColor: palette.brand.red.original,
          color: "#fff",
          "&:hover": {
            background: palette.brand.red.i4,
          },
        },
      },
      cta: {
        ...typography.appButtonText,
        textTransform: "unset",
        padding: "14px 20px",
        color: palette.brand.neutral.light,
        background: palette.brand.purple.i4,
        borderRadius: 12,
        boxShadow: "0px 2px 0px 0px #222",
        border: `2px solid ${palette.brand.neutral.dark}`,
        "&:hover": {
          background: palette.brand.purple.i4,
        },
      },
      apply: {
        ...typography.appButtonText,
        textTransform: "unset",
        width: "100%",
        borderRadius: 6,
        borderColor: palette.brand.neutral.darkGray,
        boxSizing: "border-box",
        background: palette.brand.neutral.light,
        opacity: 0.1,
        color: palette.brand.neutral.darkGray,
        border: "2px solid",
        "&:hover": {
          background: palette.brand.info.hover,
        },
        "&.dirty": {
          border: "2px solid transparent",
          opacity: 1,
          color: "white",
          background: palette.brand.info.shadow,
        },
      },
      tag: {
        ...typography.appH3,
        textTransform: "unset",
        width: "100%",
        padding: 20,
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-between",
        alignItems: "center",
        background: "unset",
        border: "2px solid",
        borderColor: "transparent",
        transition: "unset",
        "&:hover": {
          background: "initial",
        },
        "&.active": {
          background: "white",
          border: "2px solid",
          borderColor: palette.black.i2,
          borderRadius: 12,
        },
        "&.dotted": {
          border: "2px dashed",
          borderColor: palette.black.i2,
          borderRadius: 12,
        },
      },
      tab: {
        textTransform: "unset",
        ...typography.appH3,
        padding: "10px 20px",
        borderRadius: 21,
        "&.active": {
          border: "2px solid",
          borderColor: palette.black.i2,
          background: palette.white.i10,
          color: palette.black.i2,
        },
        "&.inactive": {
          "&:hover": {
            background: palette.white.i10,
            color: palette.black.i2,
          },
          background: "unset",
          color: palette.black.i2,
        },
      },
      textOutline: {
        background: "transparent",
        textTransform: "unset",
        fontWeight: 400,
        width: "fit-content",
        border: "2px solid",
        margin: 0,
        borderRadius: 6,
        color: palette.black.i1,
        borderColor: palette.black.i1,
        fontSize: 14,
        padding: 10,
        minWidth: "unset",
        lineHeight: 1,
        gap: 10,
        "&:hover": {
          background: "rgba(0,0,0,0.25)",
          opacity: 0.9,
        },
      },
      text: {
        background: "transparent",
        textTransform: "unset",
        fontSize: 16,
        fontWeight: 700,
        color: palette.black.i10,
        padding: "8px 14px",
        width: "fit-content",
        borderRadius: 18,
        "&:hover": {
          background: "rgba(0,0,0,0.1)",
        },
        "&.Mui-disabled": {},
      },
      approve: {
        background: palette.brand.green.i4,
        textTransform: "unset",
        fontSize: 16,
        fontWeight: 700,
        fontFamily: "--var(font-inter)",
        color: palette.white.i10,
        padding: "8px 14px",
        width: "fit-content",
        borderRadius: 18,
        "&:hover": {
          background: palette.brand.green.original,
        },
      },
      round: {
        borderRadius: "50%",
        minWidth: "unset",
        background: "rgba(244,244,244,0.5)",
        "&:hover": {
          background: "rgba(244, 244, 244, 0.5)",
        },
        justifyContent: "center",
        alignItems: "center",
        display: "flex",
      },
      icon: {
        background: "transparent",
        ...components.border.primary,
        width: 40,
        height: 40,
      },
    },
  },
  // AutoComplete is the entire AutoComplete component. If you want to style the text field, you must apply a style directly to the TextField component.
  MuiAutocomplete: {
    styleOverrides: {
      root: {},
      inputRoot: {
        gap: 10,
      },
      // `paper` is the parent around listbox, which is the dropdown list
      paper: {
        marginTop: 5,
        background: "white",
        minHeight: 56,
        ...components.border.primary,
      },
      noOptions: {
        color: palette.black.i1,
      },
      // `listbox` is the inner box of the items
      listbox: {
        background: "transparent",
        "& .MuiAutocomplete-option": {
          '&[data-focus="true"]': {
            // backgroundColor: "lightgreen", // Changes the background color on focus
          },
          '&[aria-selected="true"]': {
            // backgroundColor: "lightblue", // Changes the background color when selected
          },
        },
      },
    },
  },
  MuiCircularProgress: {
    styleOverrides: {
      root: {
        color: palette.black.i10,
        "&.white": {
          color: palette.white.i1,
        },
      },
    },
  },
  MuiSelect: {
    styleOverrides: {
      root: {
        color: "black",
        fontFamily: "var(--font-k2d)",
        fontWeight: 600,
        "&.Mui-focused fieldset": {
          borderColor: "rgba(0,0,0,0.5) !important",
        },
        "& .MuiOutlinedInput-notchedOutline": {
          border: "rgba(0,0,0,0.1) 2px solid",
          borderRadius: 6,
        },
        "& .MuiOutlinedInput-input": {
          padding: "10px 10px 10px 12px !important",
        },
      },
    },
  },
  MuiMenuItem: {
    styleOverrides: {
      root: {
        color: "black",
        fontFamily: "var(--font-k2d)",
      },
    },
  },
  MuiSwitch: {
    styleOverrides: {
      root: {
        "&.yellow": {
          "& .MuiSwitch-switchBase": {
            "&.Mui-checked": {
              "& + .MuiSwitch-track": {
                backgroundColor: palette.brand.yellow.i3,
              },
              "& + .MuiSwitch-track::after": {
                color: "black",
              },
            },
          },
        },
        "&.black": {
          "& .MuiSwitch-switchBase": {
            "&.Mui-checked": {
              "& + .MuiSwitch-track": {
                backgroundColor: palette.brand.neutral.dark,
              },
              "& + .MuiSwitch-track::after": {
                color: "#fff",
              },
            },
          },
        },
      },
    },
  },
  MuiTableSortLabel: {
    styleOverrides: {
      root: {
        "&:hover": {
          color: "black",
        },
      },
    },
  },
  MuiPaper: {
    styleOverrides: {
      root: {
        boxShadow: "none",
        background: "transparent",
      },
      "& .MuiPopover": {
        boxShadow: "none",
      },
      "& .MuiDialog": {
        "& .MuiPaper-root": {
          maxWidth: 1000,
        },
      },
    },
  },
  // @ts-ignore
  MuiDataGrid: {
    styleOverrides: {
      root: {
        background: "white",
        borderRadius: 6,
        border: `1px solid ${palette.brand.neutral.gray}`,
        padding: "10px 5px",
        "& .MuiDataGrid-menuIcon": {
          visibility: "visible",
          opacity: 1,
          width: "unset",
        },
      },
      panelWrapper: {
        background: "white",
        borderRadius: 6,
        border: `1px solid ${palette.brand.neutral.gray}`,
        padding: "10px 5px",
      },
    },
  },
  MuiList: {
    styleOverrides: {
      root: {
        background: "white",
        borderRadius: 12,
        border: `1px solid ${palette.brand.neutral.darkGray}`,
      },
    },
  },
  MuiDialog: {
    styleOverrides: {
      paper: {
        maxWidth: 1000,
      },
    },
  },
  // @ts-ignore
  MuiPickersPopper: {
    styleOverrides: {
      root: {
        color: "#222",
        borderRadius: 12,
        border: `2px solid ${palette.brand.neutral.darkGray}`,
        backgroundColor: "white",
      },
    },
  },
};
