import palette from "@styles/palette";
import Image from "next/image";

export const Avatar = ({
  src,
  width = 60,
  height = 60,
  hasBorder = false,
}: {
  src: string;
  width?: number;
  height?: number;
  hasBorder?: boolean;
}) => (
  <Image
    alt="Avatar"
    src={src}
    width={width}
    height={height}
    priority
    style={{
      objectFit: "cover",
      width,
      borderRadius: "50%",
      border: hasBorder ? "2px solid" : "none",
      borderColor: palette.brand.grey.i4,
    }}
    sizes={`(max-width: 768px) ${width * 2}px, (max-width: 1200px) ${width * 2}px, ${width * 2}px`}
  />
);
