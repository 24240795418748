import {
  CustomerEntity,
  CustomerRole,
  CustomerUserEntity,
  PlatformEntity,
} from "@astronautchat/schema";
import authApi from "@api/authApi";
import log from "@services/Log";
import Auth from "./Auth";

const customerUser = {
  updateActiveSource: async ({
    customerUserId,
    platformId,
  }: {
    customerUserId: CustomerUserEntity["customerUserId"];
    platformId: PlatformEntity["platformId"];
  }): Promise<void> => {
    log.info("Sending request to update active source", {
      customerUserId,
      platformId,
    });

    return await authApi.post(`/customer-user/${customerUserId}/platform/${platformId}`);
  },
  updatePlatformAccess: async ({
    customerUserId,
    addedPlatforms,
    removedPlatforms,
  }: {
    customerUserId: CustomerUserEntity["customerUserId"];
    addedPlatforms: PlatformEntity["platformId"][];
    removedPlatforms: PlatformEntity["platformId"][];
  }): Promise<void> => {
    log.info("Sending request to update platform access", {
      customerUserId,
      addedPlatforms,
      removedPlatforms,
    });

    return await authApi.post(`/customer-user/${customerUserId}/platforms`, {
      addedPlatforms,
      removedPlatforms,
    });
  },
  updateRole: async ({
    customerUserId,
    role,
  }: {
    customerUserId: CustomerUserEntity["customerUserId"];
    role: CustomerRole;
  }): Promise<void> => {
    log.info("Sending request to update CustomerUser's role", {
      customerUserId,
      role,
    });

    return await authApi.post(`/customer-user/${customerUserId}`, {
      role,
    });
  },
  remove: async ({
    customerUserId,
    customerId,
  }: {
    customerUserId: CustomerUserEntity["customerUserId"];
    customerId: CustomerEntity["customerId"];
  }): Promise<void> => {
    log.info("Sending request to delete CustomerUser", {
      customerUserId,
    });
    await authApi.delete(`/customer-user/${customerUserId}`, { data: { customerId } });
    return;
  },
  leave: async ({
    customerUserId,
    customerId,
  }: {
    customerUserId: CustomerUserEntity["customerUserId"];
    customerId: CustomerEntity["customerId"];
  }): Promise<void> => {
    log.info("Sending request to leave", {
      customerUserId,
    });
    await authApi.delete(`/customer-user/${customerUserId}`, { data: { customerId } });
    await Auth.signOut();
  },
};

export default customerUser;
