const getEnvironmentVariable = (name: string, environmentVariable: string | undefined): string => {
  if (!environmentVariable) {
    throw new Error(`Couldn't find environment variable: ${name}`);
  } else {
    return environmentVariable;
  }
};

/**
 * NextJS inlines environment variables, so the explicit env variable must be passed into the function.
 * @note this for client-side env only
 * */
export const config = {
  NEXT_PUBLIC_ORIGIN: getEnvironmentVariable("NEXT_PUBLIC_ORIGIN", process.env.NEXT_PUBLIC_ORIGIN),
  NEXT_PUBLIC_AUTH_API: getEnvironmentVariable(
    "NEXT_PUBLIC_AUTH_API",
    process.env.NEXT_PUBLIC_AUTH_API,
  ),
  NEXT_PUBLIC_AMPLITUDE_API_KEY: getEnvironmentVariable(
    "NEXT_PUBLIC_AMPLITUDE_API_KEY",
    process.env.NEXT_PUBLIC_AMPLITUDE_API_KEY,
  ),
  NEXT_PUBLIC_SENTRY_DSN: getEnvironmentVariable(
    "NEXT_PUBLIC_SENTRY_DSN",
    process.env.NEXT_PUBLIC_SENTRY_DSN,
  ),
  NEXT_PUBLIC_SENTRY_ENV: getEnvironmentVariable(
    "NEXT_PUBLIC_SENTRY_ENV",
    process.env.NEXT_PUBLIC_SENTRY_ENV,
  ),
  NEXT_PUBLIC_AWS_REGION: getEnvironmentVariable(
    "NEXT_PUBLIC_AWS_REGION",
    process.env.NEXT_PUBLIC_AWS_REGION,
  ),
  NEXT_PUBLIC_AWS_COGNITO_USER_POOL_ID: getEnvironmentVariable(
    "NEXT_PUBLIC_AWS_COGNITO_USER_POOL_ID",
    process.env.NEXT_PUBLIC_AWS_COGNITO_USER_POOL_ID,
  ),
  NEXT_PUBLIC_AWS_COGNITO_CLIENT_ID: getEnvironmentVariable(
    "NEXT_PUBLIC_AWS_COGNITO_CLIENT_ID",
    process.env.NEXT_PUBLIC_AWS_COGNITO_CLIENT_ID,
  ),
  NEXT_PUBLIC_AUTH_DOMAIN: getEnvironmentVariable(
    "NEXT_PUBLIC_AUTH_DOMAIN",
    process.env.NEXT_PUBLIC_AUTH_DOMAIN,
  ),
  NEXT_PUBLIC_REDIRECT_SIGNIN_URL: getEnvironmentVariable(
    "NEXT_PUBLIC_REDIRECT_SIGNIN_URL",
    process.env.NEXT_PUBLIC_REDIRECT_SIGNIN_URL,
  ),
  NEXT_PUBLIC_REDIRECT_SIGNOUT_URL: getEnvironmentVariable(
    "NEXT_PUBLIC_REDIRECT_SIGNOUT_URL",
    process.env.NEXT_PUBLIC_REDIRECT_SIGNOUT_URL,
  ),
  NEXT_PUBLIC_S3_URL: getEnvironmentVariable("NEXT_PUBLIC_S3_URL", process.env.NEXT_PUBLIC_S3_URL),
};
