import Image from "next/image";

type ArrowDirection = "left" | "down" | "right" | "up";

export const ChevronIcon = ({
  width = 20,
  height = 25,
  direction = "right",
  color,
}: {
  width?: number;
  height?: number;
  direction: ArrowDirection;
  color: "black" | "gray" | "light";
}) => (
  <Image
    alt="Single left pointing chevron icon"
    src={
      color === "black"
        ? "/chevron-left-black.svg"
        : color === "gray"
        ? "/chevron-left-gray.svg"
        : "/chevron-left-light.svg"
    }
    width={width}
    height={height}
    priority
    style={{
      objectFit: "contain",
      width,
      transform:
        direction === "right"
          ? "rotate(-180deg)"
          : direction == "down"
          ? "rotate(-90deg)"
          : direction === "left"
          ? "rotate(0deg)"
          : "rotate(90deg)",
      transition: "transform 0.25s linear",
    }}
    sizes={`(max-width: 768px) ${width}px, (max-width: 1200px) ${width}px, ${width}px`}
  />
);
