import { Box } from "@mui/material";
import { styled } from "@mui/material/styles";

type CenteredBoxProp = {
  isPadded?: boolean;
  flexDirection?: "row" | "column";
};

export const CenteredBox = styled(Box)<CenteredBoxProp>`
  flex-direction: ${(props) => props.flexDirection || "column"};
  justify-content: center;
  align-items: center;
  display: flex;

  ${(props) => (props.isPadded ? `padding: 10px;` : ``)}
`;
