import { ChannelSuggestion } from "@astronautchat/common-backend";
import { DiscordRoleEntity } from "@astronautchat/schema";

export function sortChannelsByFirstAlphanumeric(data: ({ name: string } & any)[]) {
  return data.sort((a, b) => {
    const regex = /[a-zA-Z0-9]/; // Regex to find the first alphanumeric character
    const firstA = a.name.match(regex)?.index; // Get the index of the first alphanumeric in the 'name' of a
    const firstB = b.name.match(regex)?.index; // Get the index of the first alphanumeric in the 'name' of b

    // Handle cases where no alphanumeric is found
    if (firstA === undefined && firstB === undefined) return 0;
    if (firstA === undefined) return 1;
    if (firstB === undefined) return -1;

    // Compare the characters at the found indices in 'name'
    return a.name[firstA].localeCompare(b.name[firstB]);
  });
}
